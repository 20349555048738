/* eslint-disable import/prefer-default-export */
import { TranslationHelpMessages, HelpMessages } from "../interfaces";

export const messages: TranslationHelpMessages = {
  [HelpMessages.titleColors]: "Colors",
  [HelpMessages.titleIcons]: "Icons",

  [HelpMessages.subtitleOperationalStates]: "Operational States",
  [HelpMessages.subtitleSituationalStates]: "Situational States",
  [HelpMessages.subtitleAlerts]: "Alerts",

  [HelpMessages.descriptionOperationalStatesGoal]: "Reflects the current condition of the vehicle in the production context.",
  [HelpMessages.descriptionOperationalStatesUtilization]: "These colors are used in dashboard columns and"
  + " vehicle icons on maps.",
  [HelpMessages.descriptionSituationalStatesGoal]: "Indicates the current situation of the vehicle.",
  [HelpMessages.descriptionSituationalStatesUtilization]: "These colors are displayed in the background color of"
  + " vehicle icons within the cards.",

  [HelpMessages.blockedOrImproperDischarge]: "Blocked or has improper discharges on current trip",
  [HelpMessages.active]: "Active vehicle",
  [HelpMessages.hasAlerts]: "Contains alerts on current trip",
  [HelpMessages.noCommunication]: "Vehicle without connection to the platform"

};
