/* eslint-disable react/require-default-props */

import React from "react";
import { Redirect, Route as ReactDOMRoute, RouteProps as ReactDOMRouterProps } from "react-router-dom";

import { useAuth } from "../hooks/useAuth";
import { DataFetcher } from "../components/DataFetcher";

interface RouteProps<T = any> extends ReactDOMRouterProps {
  restrictAccess?: "admin" | "vehiclePermission" | "driverPermission" | "locationPermission" | "none";
  isPrivate?: boolean;
  component: React.ComponentType<T>;
}

const Route: React.FC<RouteProps> = ({
  restrictAccess = "none",
  isPrivate = false,
  component: Component,
  ...rest
}: RouteProps) => {

  const { user } = useAuth();

  const userHasPermissionForRoute = (
    restrictAccess: "admin" | "vehiclePermission" | "driverPermission" | "locationPermission" | "none"
  ) => {

    if (restrictAccess === "admin") return user?.admin;
    if (restrictAccess === "vehiclePermission") return user?.allowed_change_vehicles;
    if (restrictAccess === "driverPermission") return user?.allowed_change_drivers;
    if (restrictAccess === "locationPermission") return user?.allowed_change_locations;

    return true;
  };

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location, ...rest }) => (isPrivate === !!user || location.pathname.search("reset-password") >= 0
        ? (
          userHasPermissionForRoute(restrictAccess)
            ? (
              <>
                {user && <DataFetcher />}
                <Component {...rest} />
              </>
            ) : (
              <Redirect
                to={{
                  pathname: "/main",
                  state: { location }
                }}
              />
            )
        ) : (
          <Redirect
            to={{
              pathname: isPrivate ? "/" : "/main",
              state: { location }
            }}
          />
        ))}
    />
  );
};

export default Route;
