import { AlertMessages, AlertTypeMessages, AlertNoteMessages, TranslationAlertMessages } from "./alertMessages";
import { GlobalMessages, TranslationGlobalMessages } from "./globalMessages";
import { AggregateTruckTravelDataMessages, TranslationAggregateTruckTravelDataMessages } from "./aggregateTruckTravelDataMessages";
import { YupMessages, TranslationYupMessages } from "./yupMessages";
import { MenuMessages, TranslationMenuMessages } from "./menuMessages";
import { FilterMessages, TranslationFilterMessages } from "./filterMessages";
import { MapMessages, TranslationMapMessages } from "./mapMessages";
import { ContactUsMessages, TranslationContactUsMessages } from "./contactUsMessages";
import { ServiceOrderMessages, TranslationServiceOrderMessages } from "./serviceOrderMessages";
import { LoginMessages, TranslationLoginMessages } from "./loginMessages";
import { TravelSwapMessages, TranslationTravelSwapMessages } from "./travelSwapMessages";
import { VehicleAndTravelDetailsMessages, TranslationVehicleAndTravelDetailsMessages } from "./vehicleAndTravelDetailsMessages";
import { VehicleStatusMessages, TranslationVehicleStatusMessages } from "./vehicleStatusMessages";
import { TravelDataMessages, TranslationTravelDataMessages } from "./travelDataMessages";
import { TimelineVehicleStatesMessages, TranslationTimelineVehicleStatesMessages } from "./timelineVehicleStatesMessages";
import { TimelineVehiclesItemMessages, TranslationTimelineVehiclesItemMessages } from "./timelineVehiclesItemMessages";
import { VehicleBlockMessages, TranslationVehicleBlockMessages } from "./vehicleBlockMessages";
import { VehiclesStatesMessages, VehiclesStatesTypesMessages, TranslationVehiclesStatesMessages } from "./vehiclesStatesMessages";
import { ManagementMessages, TranslationManagementMessages } from "./managementMessages";
import { DataTableMessages, TranslationDataTableMessages } from "./dataTableMessages";
import { ToastMessages, TranslationToastMessages } from "./toastMessages";
import { DriverModuleMessages, TranslationDriverModuleMessages } from "./driverModuleMessages";
import { PumpTimelineMessages, TranslationPumpTimelineMessages } from "./pumpTimelineMessages";
import { SettingsMessages, TranslationSettingsMessages } from "./settingsMessages";
import { HardwareModuleMessages, TranslationHardwareModuleMessage } from "./hardwareModuleMessages";
import { TranslateConfigCommandsMessages } from "./configCommandsMessages";
import { ConfigCommandsContentID } from "../../constants/config-commands-content.enum";
import { MailMessages, TranslationMailMessages } from "./mailMessages";
import { CoordsMessages, TranslationCoordsMessages } from "./coordsMessages";
import { LocationsModuleMessages, LocationTypeMessages, TranslationLocationsModuleMessages } from "./locationsModuleMessages";
import { ParamsMessages, TranslationParamsMessages } from "./paramsMessages";
import { SIMcardsModuleMessages, TranslationSIMCardsModuleMessages } from "./simcardsModuleMessages";
import { SurveyMessages, TranslationSurveyMessages } from "./surveyMessages";
import { TravelsModuleMessages, TranslationTravelsModuleMessages } from "./travelsModuleMessages";
import { UsersModuleMessages, TranslationUsersModuleMessages } from "./usersModuleMessages";
import { VehiclesModuleMessages, VehicleTypesMessages, TranslationVehiclesModuleMessages } from "./vehiclesModuleMessages";
import { CoordinateAnalysisMessages, TranslationCoordinateAnalysisMessages } from "./coordinateAnalysisMessages";
import { TravelHistoryMessages, TranslationTravelHistoryMessages } from "./travelHistoryMessages";
import { VehiclesHistoryMessages, TranslationVehiclesHistoryMessages } from "./vehiclesHistoryMessages";
import { EventsMessages, EventTypesMessages, TranslationEventsMessages } from "./eventsMessages";
import { VehicleMaintenanceMessages, TranslationVehicleMaintenanceMessages } from "./vehicleMaintenanceMessages";
import { TranslationTravelAlertFullSpeedMessage, TravelAlertFullSpeedMessage } from "./travelAlertFullSpeedMessage";
import { HelpMessages, TranslationHelpMessages } from "./helpMessages";
import { DualListboxMessages, TranslationDualListboxMessages } from "./dualListboxMessages";
import { RegionalsModuleMessages, TranslationRegionalsModuleMessages } from "./regionalsModuleMessages";
import { GroupsModuleMessages, TranslationGroupsModuleMessages } from "./groupsModuleMessages";
import { ScheduledReportMessages, TranslationScheduledReportMessages } from "./scheduledReportMessages";

export type {
  TranslationAlertMessages,
  TranslationGlobalMessages,
  TranslationAggregateTruckTravelDataMessages,
  TranslationYupMessages,
  TranslationMenuMessages,
  TranslationFilterMessages,
  TranslationMapMessages,
  TranslationContactUsMessages,
  TranslationServiceOrderMessages,
  TranslationLoginMessages,
  TranslationTravelSwapMessages,
  TranslationVehicleAndTravelDetailsMessages,
  TranslationVehicleStatusMessages,
  TranslationTravelDataMessages,
  TranslationTimelineVehicleStatesMessages,
  TranslationTimelineVehiclesItemMessages,
  TranslationVehicleBlockMessages,
  TranslationVehiclesStatesMessages,
  TranslationManagementMessages,
  TranslationDataTableMessages,
  TranslationToastMessages,
  TranslationDriverModuleMessages,
  TranslationPumpTimelineMessages,
  TranslationSettingsMessages,
  TranslationHardwareModuleMessage,
  TranslateConfigCommandsMessages,
  TranslationMailMessages,
  TranslationCoordsMessages,
  TranslationLocationsModuleMessages,
  TranslationRegionalsModuleMessages,
  TranslationGroupsModuleMessages,
  TranslationParamsMessages,
  TranslationSIMCardsModuleMessages,
  TranslationSurveyMessages,
  TranslationTravelsModuleMessages,
  TranslationUsersModuleMessages,
  TranslationVehiclesModuleMessages,
  TranslationCoordinateAnalysisMessages,
  TranslationTravelHistoryMessages,
  TranslationVehiclesHistoryMessages,
  TranslationEventsMessages,
  TranslationVehicleMaintenanceMessages,
  TranslationTravelAlertFullSpeedMessage,
  TranslationHelpMessages,
  TranslationDualListboxMessages,
  TranslationScheduledReportMessages
};

export {
  AlertMessages,
  AlertTypeMessages,
  GlobalMessages,
  AggregateTruckTravelDataMessages,
  YupMessages,
  MenuMessages,
  FilterMessages,
  MapMessages,
  ContactUsMessages,
  ServiceOrderMessages,
  LoginMessages,
  TravelSwapMessages,
  VehicleAndTravelDetailsMessages,
  VehicleStatusMessages,
  TravelDataMessages,
  TimelineVehicleStatesMessages,
  TimelineVehiclesItemMessages,
  VehicleBlockMessages,
  VehiclesStatesMessages,
  VehiclesStatesTypesMessages,
  ManagementMessages,
  DataTableMessages,
  ToastMessages,
  DriverModuleMessages,
  HardwareModuleMessages,
  PumpTimelineMessages,
  SettingsMessages,
  ConfigCommandsContentID,
  MailMessages,
  CoordsMessages,
  LocationsModuleMessages,
  RegionalsModuleMessages,
  GroupsModuleMessages,
  ParamsMessages,
  SIMcardsModuleMessages,
  SurveyMessages,
  TravelsModuleMessages,
  UsersModuleMessages,
  VehiclesModuleMessages,
  VehicleTypesMessages,
  CoordinateAnalysisMessages,
  TravelHistoryMessages,
  LocationTypeMessages,
  VehiclesHistoryMessages,
  EventsMessages,
  EventTypesMessages,
  VehicleMaintenanceMessages,
  AlertNoteMessages,
  TravelAlertFullSpeedMessage,
  HelpMessages,
  DualListboxMessages,
  ScheduledReportMessages
};
