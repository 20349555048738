import React from "react";
import { ReactSVG } from "react-svg";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";

// region Libraries
import AccessTimeIcon from "@material-ui/icons/AccessTime";
// endregion Libraries
// region Languages
import useTranslation from "src/translations/useTranslation";
import { GlobalMessages, TimelineVehicleStatesMessages, VehiclesStatesTypesMessages } from "@shared/languages/interfaces";
// region Constants
import { VehicleStatesTypesID } from "@shared/constants/vehicle-states-types.enum";
import { AlertTypesID } from "@shared/constants/alert-types.enum";
// endregion Constants
// region Interfaces
import { Vehicle } from "@shared/interfaces/vehicle.interface";
// endregion Interfaces
// region Assets
import LockIcon from "@assets/icons/lockIcon.svg";
// endregion Assets
// region Utils
import { formatOdometer } from "@shared/utils/useful-functions";
import utils from "../../../utils/useful-functions";
// endregion Utils
// region Styles
import { Container } from "./styles";
// endregion Styles

type TimeLineVehicleStatesProps = { vehicle: Vehicle, historicalTravel?: boolean, showDate?: boolean };
const TimeLineVehicleStates: React.FC<TimeLineVehicleStatesProps> = ({
  vehicle, historicalTravel
}) => {

  const blockAlerts = vehicle.alerts.filter((alert) => alert.type.id_alert_type === AlertTypesID.BLOQUEIO);
  const lastBlockAlert = blockAlerts[blockAlerts.length - 1];

  const sortedStates = vehicle.states.sort((a, b) => new Date(a.registration_date).getTime() - new Date(b.registration_date).getTime());

  const { t } = useTranslation();

  return (
    <Container>
      <div className="title">
        {t(TimelineVehicleStatesMessages.statesTitle)}
      </div>
      <VerticalTimeline layout="1-column" chi>
        {vehicle.states ? (
          sortedStates.map((state, index, statesArray) => (
            <VerticalTimelineElement
              key={state.id_vehicle_state}
              contentStyle={{ background: "rgb(221 221 221 / 80%)", color: "black" }}
              contentArrowStyle={{ borderRight: "7px solid  rgb(221 221 221 / 80%)" }}
              icon={utils.getStateIcon(state.status.id_vehicle_state_type)}
            >
              <div className="block flex">
                <div
                  className="duration-title"
                  style={{
                    color: index === vehicle.states.length - 1
                      ? "red"
                      : "black"
                  }}
                >
                  {t(VehiclesStatesTypesMessages[state.status.id_vehicle_state_type])}
                </div>

                {(() => {
                  let show = true;

                  if (historicalTravel && statesArray.length - 1 === index) {
                    show = false;
                  }
                  if (show) {
                    return (
                      <div className="odometer">
                        {formatOdometer(((state?.finish_odometer === 0
                          ? vehicle.current_odometer
                          : state.finish_odometer) || 0) - state.start_odometer)}
                      </div>
                    );
                  }

                  return <></>;
                })()}

              </div>
              {index !== 0 && index !== vehicle.states.length - 1 && state.status.id_vehicle_state_type
                !== VehicleStatesTypesID.EM_OBRA
                && state.status.id_vehicle_state_type !== VehicleStatesTypesID.DESCARREGANDO && (
                  <div className="block">
                    {state.location && (
                      state.location.name
                      || (
                        <>
                          {state.location.address}, {state.location.number || "S/N"} - {state.location.district}
                        </>
                      )
                    )}
                  </div>
              )}
              <div className="block">
                <div className="content">
                  <div className="subtitle">
                    {t(GlobalMessages.start)}
                  </div>
                  <div className="text">{utils.formatDateIfHave(state.registration_date, "fullDateWithoutYear")}</div>
                </div>
                {state.finish_date && (
                  <div className="content">
                    <div className="subtitle">{t(GlobalMessages.end)}</div>
                    <div className="text">
                      {utils.formatDateIfHave(state.finish_date, "fullDateWithoutYear")}
                    </div>
                  </div>
                )}
                <div className="content">
                  {(state.duration || !historicalTravel)
                    && (
                      <>
                        <div className="subtitle">{t(GlobalMessages.duration)}</div>
                        <div className="text" style={{ display: "flex", alignItems: "center" }}>
                          {
                            state.duration
                              ? utils.formatDateIfHave(state.duration, "durationDescriptiveTime")
                              : utils.formatDateIfHave(utils.calcDataRange(state.registration_date, new Date()),
                                "durationDescriptiveTime")
                          }
                        </div>
                      </>
                    )}
                </div>
                {state.mixer_pressure && (
                  <div className="content">
                    <>
                      <div className="subtitle">{t(TimelineVehicleStatesMessages.manometer)}</div>
                      <div className="text" style={{ display: "flex", alignItems: "center" }}>
                        {state.mixer_pressure.toString().replace(".", ",")} bar
                      </div>
                    </>
                  </div>
                )}

              </div>
            </VerticalTimelineElement>
          ))
        ) : (
          <></>
        )}
        {vehicle.blocked && (
          <VerticalTimelineElement
            contentStyle={{ background: "#F00", color: "white" }}
            contentArrowStyle={{ borderRight: "7px solid red" }}
            icon={<ReactSVG src={LockIcon} />}
          >
            <div className="block flex">
              <div
                className="duration-title"
                style={{ color: "white", fontWeight: "bold" }}
              >
                {t(TimelineVehicleStatesMessages.blockedVehicle)}
              </div>
            </div>
            <div className="block">
              <div className="content">
                <div className="subtitle">
                  {t(TimelineVehicleStatesMessages.blockingDate)}
                </div>
                <div className="text">
                  {utils.formatDateIfHave(lastBlockAlert?.occurrence_date, "fullDateWithoutYear")}
                </div>
              </div>
              <div className="content">
                <div className="subtitle">{t(GlobalMessages.duration)}</div>
                <div className="text" style={{ display: "flex", alignItems: "center" }}>
                  {utils.formatDateIfHave(utils.calcDataRange(lastBlockAlert?.occurrence_date, new Date()),
                    "durationDescriptiveTime")}
                  <AccessTimeIcon style={{ height: "18px" }} />
                </div>
              </div>
            </div>
          </VerticalTimelineElement>
        )}
      </VerticalTimeline>
    </Container>
  );
};

TimeLineVehicleStates.defaultProps = {
  showDate: true
};

export default TimeLineVehicleStates;
