import React, { useCallback, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { Link, useHistory } from "react-router-dom";

// region Imports - Libraries
import * as Yup from "yup";
// endregion Imports - Libraries
// region Imports - Languages
import useTranslation from "src/translations/useTranslation";
import { GlobalMessages, LoginMessages, YupMessages } from "@shared/languages/interfaces";
// region Imports - Atoms
import Logo from "@atoms/FleetLogo";
import InputPassword from "@atoms/Login/InputPassword";
import InputEmail from "@atoms/Login/InputEmail";
import Button from "@atoms/Login/Button";
// endregion Imports - Atoms
// region Imports - Components
import DialogConfirmAction from "@components/Dialog/ConfirmAction";
import LanguageIcon from "@components/LanguageIcon";
// endregion Imports - Components
// region Imports - Unform
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
// endregion Imports - Unform
// region Imports - Hooks
import { useAuth } from "@hooks/useAuth";
import { useToast } from "@hooks/useToast";
import getValidationErrors from "@hooks/getValidationErrors";
// endregion Imports - Hooks
// region Imports - Services
import { socket } from "@services/websocketContext";
import api from "@services/api";
// endregion Imports - Services
// region Imports - Styles
import { Container, Content } from "./styles";
// endregion Imports - Styles

// Create expire session function to avoid code repetition, including the dialog
const handleUnauthorized = () => {

  // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require
  const i18n = require("i18next");

  const expireSession = () => {

    localStorage.removeItem("@Fleet:token");
    localStorage.removeItem("@Fleet:currentSurvey");
    localStorage.removeItem("@Fleet:google");

    window.location.href = "/";

    socket.emit("logout");

  };

  ReactDOM.render(
    <DialogConfirmAction
      open
      title={i18n.t(LoginMessages.expiredSession)}
      onClose={() => ""}
      actions={[
        { text: i18n.t(LoginMessages.goToLoginPage), action: () => expireSession() }
      ]}
    >
      {i18n.t(LoginMessages.expiredSessionInstructions)}
    </DialogConfirmAction>,
    document.getElementById("extra")
  );
};

// Listen to invalid token event from server and redirect to login page
socket.on("invalidToken", (token: string) => {

  // Call handleUnauthorized function only if user is logged in
  if (localStorage.getItem("@Fleet:token") && token !== null) {

    handleUnauthorized();

    // Remove listener to avoid multiple calls
    socket.off("invalidToken");
  }
});

// Exception to all errors of API (Axios)
api.interceptors.response.use((response) => response, (error) => {

  // If occurs unauthorized error, show dialog and redirect to login page
  if (error.response && error.response.data.statusCode === 401) {

    handleUnauthorized();

    return;
  }

  throw error;
});

interface SignInFormData {
  email: string;
  password: string;
}

const SignIn: React.FC = () => {

  // region Hooks
  const { addToast } = useToast();
  const { signIn } = useAuth();
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const { t } = useTranslation();
  // endregion Hooks
  // region States
  const [loading, setLoading] = useState(false);
  // endregion States
  // region Functions

  const handleSubmit = useCallback(
    async (formData: SignInFormData) => {

      try {

        formRef.current?.setErrors({});

        // Define form rules
        const schema = Yup.object().shape({
          email: Yup.string().trim().required(t(YupMessages.emailRequired)).email(t(YupMessages.invalidEmail)),
          password: Yup.string().required(t(YupMessages.passwordRequired)).min(6, t(YupMessages.passwordMinLength))
        });

        // Validate inputs
        await schema.validate(formData, { abortEarly: false });

        // Request login access
        setLoading(true);
        await signIn({ email: formData.email, password: formData.password });

        history.push("/main");

      } catch (error: any) {

        // Err of form validation according shape
        if (error instanceof Yup.ValidationError) {

          const errors = getValidationErrors(error);

          formRef.current?.setErrors(errors);

          return;
        }

        if (!error.response) addToast({ type: "error", title: t(GlobalMessages.error), description: t(GlobalMessages.connectionNotEstablished) });
        else {
          addToast({
            type: error.response.data.status === "alert" ? "info" : "error",
            title: error.response.data.backend,
            description: error.response.data.message
          });
        }

      } finally {
        setLoading(false);
      }

    }, [addToast, history, signIn, t]
  );
  // endregion Functions

  return (
    <Container>
      <Content>
        <Logo />
        <Form ref={formRef} onSubmit={handleSubmit}>
          <InputEmail name="email" />
          <InputPassword name="password" />
          <Button type="submit" loading={loading}>{t(LoginMessages.login)}</Button>
          <Link to="/forgot-password">{t(LoginMessages.forgotMyPassword)}</Link>
        </Form>
      </Content>
      <LanguageIcon />
    </Container>
  );
};

export default SignIn;
