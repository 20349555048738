/* eslint-disable import/prefer-default-export */
import {
  TranslationYupMessages,
  YupMessages
} from "../interfaces";

export const messages: TranslationYupMessages = {
  [YupMessages.justificationRequired]: "A justificativa é obrigatória",
  [YupMessages.expectedDateRequired]: "A data prevista é obrigatória",
  [YupMessages.expectedDateGreaterThanCurrentDate]: "A data prevista deve ser maior que a data atual",
  [YupMessages.originLocationRequired]: "Local de origem é obrigatório",
  [YupMessages.loadingLocationRequired]: "Local de carregamento é obrigatório",
  [YupMessages.unloadingLocationRequired]: "Local de descarregamento é obrigatório",
  [YupMessages.vehicleRequired]: "Veículo é obrigatório",
  [YupMessages.productRequired]: "Produto é obrigatório",
  [YupMessages.serviceOrderTypeRequired]: "Tipo de O.S. é obrigatório",
  [YupMessages.emailRequired]: "E-mail é obrigatório",
  [YupMessages.passwordRequired]: "Senha é obrigatória",
  [YupMessages.passwordMinLength]: "A senha deve ter no mínimo 6 caracteres",
  [YupMessages.invalidEmail]: "Digite um email válido",
  [YupMessages.passwordsDontMatch]: "As senhas não conferem",
  [YupMessages.justificationMinLength15]: "A justificativa deve ter no mínimo 15 caracteres",
  [YupMessages.finishDateGreaterThanStartDate]: "A data final deve ser posterior à data inicial",
  [YupMessages.maxDateInterval24h]: "O intervalo máximo entre as datas é de 24 horas",
  [YupMessages.maxDateInterval7d]: "O intervalo máximo entre as datas é de 7 dias",
  [YupMessages.maxDateInterval30d]: "O intervalo máximo entre as datas é de 30 dias",
  [YupMessages.codeRequired]: "Código é obrigatório",
  [YupMessages.incompleteLicensePlate]: "Placa incompleta",
  [YupMessages.licensePlateRequired]: "Placa é obrigatória",
  [YupMessages.typeRequired]: "Tipo é obrigatório",
  [YupMessages.descriptionRequired]: "Descrição é obrigatória",
  [YupMessages.callerIDRequired]: "Caller ID é obrigatório",
  [YupMessages.ICCIDRequired]: "ICCID é obrigatório",
  [YupMessages.phoneCompanyRequired]: "A empresa de telefonia é obrigatória",
  [YupMessages.purchaseDateRequired]: "Data de compra é obrigatória",
  [YupMessages.fieldRequired]: "Campo obrigatório",
  [YupMessages.fieldMaxLength30]: "O campo deve ter no máximo 30 caracteres"
};
