/* eslint-disable import/prefer-default-export */
import { TranslationVehicleAndTravelDetailsMessages, VehicleAndTravelDetailsMessages } from "../interfaces";

export const messages: TranslationVehicleAndTravelDetailsMessages = {
  [VehicleAndTravelDetailsMessages.noActiveTravel]: "Ninguna viaje activa en el vehículo!",
  [VehicleAndTravelDetailsMessages.noActiveTravelDescription]: "Necesario viaje activa para búsqueda de coordenadas",
  [VehicleAndTravelDetailsMessages.noDescriptionRegistered]: "Sin descripción registrada",
  [VehicleAndTravelDetailsMessages.construction]: "Obra",
  [VehicleAndTravelDetailsMessages.alertsTitle]: "Alertas",
  [VehicleAndTravelDetailsMessages.noAlertsOnTravel]: "Ninguna alerta referente al viaje",
  [VehicleAndTravelDetailsMessages.currentLoad]: "Carga actual",
  [VehicleAndTravelDetailsMessages.waterAdded]: "Agua agregada",
  [VehicleAndTravelDetailsMessages.litersAdded]: "Litros agregados",
  [VehicleAndTravelDetailsMessages.noWaterAdded]: "Ninguna adición de agua",
  [VehicleAndTravelDetailsMessages.travelStatus]: "Status del viaje",
  [VehicleAndTravelDetailsMessages.mapPosition]: "Posición en el mapa",
  [VehicleAndTravelDetailsMessages.allocation]: "Asignación",
  [VehicleAndTravelDetailsMessages.lockVehicle]: "Bloquear vehículo",
  [VehicleAndTravelDetailsMessages.unlockVehicle]: "Desbloquear vehículo",
  [VehicleAndTravelDetailsMessages.readWaterAdditionsSuccess]: "Adiciones de agua retornadas con éxito",
  [VehicleAndTravelDetailsMessages.noWaterAdditionsFound]: "Ninguna adición de agua encontrada",
  [VehicleAndTravelDetailsMessages.readWaterAdditionsError]: "Falla al buscar adiciones de agua"
};
