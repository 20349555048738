import React, { useCallback, useRef, useState } from "react";
import { FiLogIn } from "react-icons/fi";
import { Link } from "react-router-dom";

// region Libraries
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import * as Yup from "yup";
// endregion Libraries
// region Languages
import useTranslation from "src/translations/useTranslation";
import { GlobalMessages, LoginMessages, YupMessages } from "@shared/languages/interfaces";
// endregion Languages
// region Components
import Logo from "src/atoms/FleetLogo";
import InputEmail from "@atoms/Login/InputEmail";
import Button from "@atoms/Login/Button";
import LanguageIcon from "@components/LanguageIcon";
// endregion Components
// region Services
import api from "@services/api";
// endregion Services
// region Hooks
import getValidationErrors from "../../../hooks/getValidationErrors";
import { useToast } from "../../../hooks/useToast";
// endregion Hooks
// region Styles
import {
  Container, Content
} from "./styles";
// endregion Styles

interface ForgotPasswordFormData {
  email: string;
  password: string;
}

const ForgotPassword: React.FC = () => {

  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();
  const { t } = useTranslation();
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (formData: ForgotPasswordFormData) => {

      try {

        formRef.current?.setErrors({});

        // Define form rules
        const schema = Yup.object().shape({
          email: Yup.string().required(t(YupMessages.emailRequired)).email(t(YupMessages.invalidEmail))
        });

        // Validate inputs
        await schema.validate(formData, { abortEarly: false });

        // Request forgot password on sever
        setLoading(true);
        const { data } = await api.post("auth/send-forgot-password-mail", formData);

        if (data.status === "success") addToast({ type: "success", title: t(GlobalMessages.success), description: data.message });
        addToast({ type: "info", title: t(GlobalMessages.alert) , description: data.message });

      } catch (error: any) {

        // Err of form validation according shape
        if (error instanceof Yup.ValidationError) {

          const errors = getValidationErrors(error);

          formRef.current?.setErrors(errors);

          return;
        }

        if (!error.response) addToast({ type: "error", title: t(GlobalMessages.error), description: t(GlobalMessages.connectionNotEstablished) });
        else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

      } finally {
        setLoading(false);
      }

    }, [addToast, t]
  );

  return (
    <Container>
      <Content>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Logo />
          <InputEmail name="email" />
          <Button className="button" loading={loading} type="submit">
            {t(LoginMessages.recoverPassword)}
          </Button>
          <Link to="/"><FiLogIn /> {t(LoginMessages.goToLoginPage)}</Link>
        </Form>
      </Content>
      <LanguageIcon />
    </Container>
  );
};

export default ForgotPassword;
