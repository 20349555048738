import React from "react";
import { Switch } from "react-router-dom";

import PumpTimeline from "../pages/PumpTimeline";
import AggregateTrucksTimeline from "../pages/AggregateTrucksTimeline";
import SupportVehicleDashboard from "../pages/SupportVehicleDashboard";
import ForgotPassword from "../pages/Login/ForgotPassword";
import ResetPassword from "../pages/Login/ResetPassword";
import SignIn from "../pages/Login/SignIn";
import Main from "../pages/Main";
import CoordinateAnalysisMain from "../pages/Reports/CoordinateAnalysis";
import Users from "../pages/Management/Users";
import Settings from "../pages/Settings";
import Driver from "../pages/Modules/Drivers";
import Hardwares from "../pages/Modules/Hardwares";
import Locations from "../pages/Modules/Locations";
import Vehicle from "../pages/Modules/Vehicles";
import ScheduledReports from "../pages/Reports/ScheduledReports";
import AlertsHistory from "../pages/Reports/AlertsHistory";
import TravelHistoryMain from "../pages/Reports/TravelsHistory";
import VehiclesHistory from "../pages/Reports/VehiclesHistory";
import SimCardsList from "../pages/Modules/SimCards";
import Regionals from "../pages/Modules/Regionals";
import Groups from "../pages/Modules/Groups";

import Route from "./Route";

const Routes: React.FC = () => (
  <Switch> { /* Permitir que apenas uma unica rota seja chamada ao mesmo tempo */ }
    <Route exact path="/" component={SignIn} /> { /* exact = Só chama a rota quando o path corresponder exatamente */ }
    <Route exact path="/forgot-password" component={ForgotPassword} />
    <Route exact path="/reset-password/:token" component={ResetPassword} />
    <Route exact path="/main" component={Main} isPrivate />
    <Route exact path="/reports/scheduled-reports" component={ScheduledReports} isPrivate />
    <Route exact path="/reports/travels-history" component={TravelHistoryMain} isPrivate />
    <Route exact path="/reports/alerts-history" component={AlertsHistory} isPrivate />
    <Route exact path="/reports/coordinate-analysis" component={CoordinateAnalysisMain} isPrivate />
    <Route exact path="/reports/vehicles-history" component={VehiclesHistory} isPrivate />
    <Route exact path="/modules/drivers" component={Driver} isPrivate restrictAccess="driverPermission" />
    <Route exact path="/modules/vehicles" component={Vehicle} isPrivate restrictAccess="vehiclePermission" />
    <Route exact path="/modules/locations" component={Locations} isPrivate restrictAccess="locationPermission" />
    <Route exact path="/modules/regionals" component={Regionals} isPrivate />
    <Route exact path="/modules/groups" component={Groups} isPrivate />
    <Route exact path="/modules/hardwares" component={Hardwares} isPrivate />
    <Route exact path="/modules/simcards" component={SimCardsList} isPrivate />
    <Route exact path="/management/users" component={Users} isPrivate restrictAccess="admin" />
    <Route exact path="/settings" component={Settings} isPrivate />
    <Route exact path="/timeline" component={PumpTimeline} isPrivate />
    <Route exact path="/support-vehicles" component={SupportVehicleDashboard} isPrivate />
    <Route exact path="/aggregate-trucks" component={AggregateTrucksTimeline} isPrivate />
  </Switch>
);

export default Routes;
