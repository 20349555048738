import styled from "styled-components";

export const Container = styled.div`

  .waterAddition {
    box-shadow: none;

    .boxHeaderItemsEvent {
      padding: 0;
      align-items: center;
      display: block;
      margin-bottom: 0;
      margin-top: 12px;

      .content {
        width: 100%;
        display: flex;
        align-items: center;

        .boxHeaderItemsIcon {

          svg {
            width: 33px;
            height: 33px;
          }
        }

        .boxHeaderItemsDescription {
          width: 100%;
          padding-left: 15px;
          display: flex;
          flex-direction: column;

          .heading {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .type {
              padding-right: 10px;
              width: 8rem;
            }
          }

          .typeAndDuration {
            font-weight: 600;
            font-size: 1rem;

            .duration {
              display: flex;
              width: 100px;
              justify-content: flex-end;
            }
          }

          .startDate {
            font-size: 0.8rem;
            color: black;
          }

        }
      }
    }

  }
`;
