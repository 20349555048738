/* eslint-disable import/prefer-default-export */
import { TranslationFilterMessages, FilterMessages } from "../interfaces";

export const messages: TranslationFilterMessages = {
  [FilterMessages.filtersTitle]: "Filtros",
  [FilterMessages.noVehicles]: "Nenhum veículo no momento",
  [FilterMessages.noVehiclesLinkedToAPlant]: "Nenhum veículo vinculado a uma usina",
  [FilterMessages.noVehiclesLinkedToARegional]: "Nenhum veículo vinculado a uma regional",
  [FilterMessages.noVehiclesLinkedToAGroup]: "Nenhum veículo vinculado a um grupo",
  [FilterMessages.noConstruction]: "Nenhuma obra no momento",
  [FilterMessages.selectSomethingToFilter]: "Selecione algo para filtrar",
  [FilterMessages.selectAtLeastOneVehicleType]: "Selecione ao menos um tipo de veículo",
  [FilterMessages.concreteMixers]: "Betoneiras",
  [FilterMessages.pumpTrucks]: "Caminhões bomba",
  [FilterMessages.supportVehicles]: "Veículos de apoio",
  [FilterMessages.loaders]: "Carregadeiras",
  [FilterMessages.clearFilters]: "Limpar filtros",
  [FilterMessages.applyFilters]: "Aplicar filtros",
  [FilterMessages.filterInstructions]: "Filtre as informações para melhores resultados",

  [FilterMessages.optionVehicleTypes]: "Tipos de veículo",
  [FilterMessages.optionStartDate]: "Data inicial",
  [FilterMessages.optionEndDate]: "Data final",
  [FilterMessages.optionVehicles]: "Veículos",
  [FilterMessages.optionPlants]: "Usinas",
  [FilterMessages.optionConstructions]: "Obras",
  [FilterMessages.optionClients]: "Clientes",
  [FilterMessages.optionAlertTypes]: "Tipos de alerta",
  [FilterMessages.optionDrivers]: "Motoristas",
  [FilterMessages.optionRegionals]: "Regionais",
  [FilterMessages.optionGroups]: "Grupos",
  [FilterMessages.optionTravels]: "Viagens",
  [FilterMessages.optionReportType]: "Tipo de relatório",
  [FilterMessages.optionVehicleStateTypes]: "Tipos de estado",
  [FilterMessages.optionUtcOffset]: "Desvio de Horário (UTC)",

  [FilterMessages.applicationVehicleHistory]: "Histórico de veículos",
  [FilterMessages.applicationAlerts]: "Histórico de alertas",
  [FilterMessages.applicationTravelHistory]: "Histórico de viagens",
  [FilterMessages.applicationCoordinateHistory]: "Histórico de coordenadas",

  [FilterMessages.dateRangeLabel]: "Intervalo",
  [FilterMessages.filterOptions]: "Filtrar opções",
  [FilterMessages.selectAll]: "Selecionar todos",
  [FilterMessages.removeAll]: "Remover todos",
  [FilterMessages.selectAllFiltered]: "Selecionar todos os itens filtrados",
  [FilterMessages.removeAllFiltered]: "Remover todos os itens filtrados",
  [FilterMessages.savedFilters]: "Filtros salvos",
  [FilterMessages.chooseFilterName]: "Escolha o nome do filtro",
  [FilterMessages.filterName]: "Nome do filtro",
  [FilterMessages.saveNewFilter]: "Salvar seleções como novo filtro",
  [FilterMessages.saveChanges]: "Salvar alterações no filtro selecionado",

  // Filter backend messages
  [FilterMessages.noFilterFound]: "Nenhum filtro encontrado",
  [FilterMessages.readSuccess]: "Filtros retornados com sucesso",
  [FilterMessages.readError]: "Erro ao buscar filtros",
  [FilterMessages.createSuccess]: "Filtro criado com sucesso",
  [FilterMessages.createError]: "Erro ao criar filtro",
  [FilterMessages.updateSuccess]: "Filtro atualizado com sucesso",
  [FilterMessages.updateError]: "Erro ao atualizar filtro",
  [FilterMessages.deleteSuccess]: "Filtro removido com sucesso",
  [FilterMessages.deleteError]: "Erro ao remover filtro"
};
