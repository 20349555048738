import { UsersModuleMessages, TranslationUsersModuleMessages } from "../interfaces";

export const messages: TranslationUsersModuleMessages = {
  [UsersModuleMessages.createUserSuccess]: "Usuário registrado con éxito",
  [UsersModuleMessages.confirmationEmailSent]: "Correo electrónico de confirmación enviado",
  [UsersModuleMessages.createUserError]: "Falla al registrar nuevo usuario",
  [UsersModuleMessages.readUserSuccess]: "Usuario(s) devuelto(s) con éxito",
  [UsersModuleMessages.noUserFound]: "Ningún usuario encontrado",
  [UsersModuleMessages.readUserError]: "Falla al buscar usuario(s)",
  [UsersModuleMessages.updateUserSuccess]: "Usuario actualizado con éxito",
  [UsersModuleMessages.userNotFound]: "Usuario no registrado en nuestro sistema",
  [UsersModuleMessages.updateUserError]: "Falla al actualizar usuario",
  [UsersModuleMessages.deleteUserSuccess]: "Usuario eliminado con éxito",
  [UsersModuleMessages.deleteUserError]: "Falla al eliminar usuario",
  [UsersModuleMessages.inactiveAccountCheckEmail]: "Cuenta aún no activada, verifique su correo electrónico para activar",
  [UsersModuleMessages.inactiveUser]: "Usuario inactivo",
  [UsersModuleMessages.userNotAdmin]: "El usuario no tiene derechos de administrador"
};
