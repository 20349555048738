/* eslint-disable import/prefer-default-export */

import { AlertMessages, TranslationAlertMessages } from "../interfaces";

export const messages: TranslationAlertMessages = {

  /* region Frontend messages */
  [AlertMessages.noPermissionToJustify]: "You do not have permission to justify alerts of type",
  [AlertMessages.siteRegistration]: "Site registration",
  [AlertMessages.searchLocation]: "Search location",
  [AlertMessages.alertConfirmation]: "Alert confirmation",
  [AlertMessages.vehicleAtTheAlertMoment]: "Vehicle at the alert moment",
  [AlertMessages.expirationDate]: "Expiration date",
  [AlertMessages.occurrenceDate]: "Occurrence date",
  [AlertMessages.expiresIn]: "Expires in",
  [AlertMessages.justification]: "Justification",
  [AlertMessages.details]: "Details",
  [AlertMessages.expiredIn]: "Expired in",
  [AlertMessages.unauthorizedStop]: "Unauthorized stop",
  [AlertMessages.expiredConcrete]: "Expired concrete",
  [AlertMessages.block]: "Block",
  [AlertMessages.concreteAboutToExpire]: "Concrete about to expire",
  [AlertMessages.mainBatteryConnection]: "Main battery connection",
  [AlertMessages.mainBatteryDisconnection]: "Main battery disconnection",
  [AlertMessages.unauthorizedUnloading]: "Improper discharge",
  [AlertMessages.speeding]: "Speeding",
  [AlertMessages.speedingTravel]: "Speeding by travel",
  [AlertMessages.hardwareInactivity]: "Hardware inactivity",
  [AlertMessages.maintenance]: "Vehicle maintenance",
  [AlertMessages.jammingDetected]: "Jammer event",
  [AlertMessages.observation]: "Observation",
  [AlertMessages.licensePlate]: "License Plate",
  [AlertMessages.justificationDeleteConfirmation]: "Do you want to delete the justification:",
  [AlertMessages.intervalOfDays]: "Your license will expire in {{days}} day(s)!<br/>"
    + "Please contact our financial department by {{limitDate}} to regularize the situation.<br/>"
    + "Contacts: WhatsApp / Phone (11) 3564-3314 or E-mail: financeiro@topconsuite.com.",
  [AlertMessages.blockedPlataforom]: "platform blocked from users",

  /* region Alerts History messages */
  [AlertMessages.alert]: "Alert",
  [AlertMessages.confirmationDate]: "Confirmation date",
  [AlertMessages.authorizer]: "Authorizer",
  [AlertMessages.alertsHistoryTitle]: "Alerts history",
  [AlertMessages.startDate]: "Start date",
  [AlertMessages.finishDate]: "Final date",
  [AlertMessages.type]: "Type",
  /* endregion */

  /* region Alert note messages */
  [AlertMessages.noTxCommunication]: "No communication with TX sensor for more than 48 hours",
  [AlertMessages.noRxCommunication]: "No communication with RX sensor for more than 24 hours",
  [AlertMessages.lowBattery]: "Low sensor battery",
  [AlertMessages.possibleViolation]: "Possible sensor violation",
  [AlertMessages.unknown]: "Unknown sensor status diagnosis",
  /* endregion */

  /* endregion Frontend messages */
  /* region Backend messages */
  [AlertMessages.readAlertTypesSuccess]: "Alert type(s) returned successfully",
  [AlertMessages.readAlertSuccess]: "Alert(s) returned successfully",
  [AlertMessages.confirmAlertSuccess]: "Alert confirmed successfully",
  [AlertMessages.createAlertSuccess]: "Alert created successfully Type: ",
  [AlertMessages.noAlertType]: "No alert type found",
  [AlertMessages.readAlertTypesError]: "Failed to fetch alert type(s)",
  [AlertMessages.noAlertFound]: "No alert found",
  [AlertMessages.readAlertError]: "Failed to fetch alerts",
  [AlertMessages.alertNotRegistered]: "Alert not registered in our system",
  [AlertMessages.noVehicleLinkedToHardware]: "No vehicle linked to hardware",
  [AlertMessages.alertNotMapped]: "Unmapped alert",
  [AlertMessages.checkVehicleTrackerAlertError]: "Failed to check vehicle alert command",
  [AlertMessages.createAlertJustificationSuccess]: "Justification saved successfully",
  [AlertMessages.createAlertJustificationError]: "Failed to save justification",
  [AlertMessages.readAlertJustificationSuccess]: "Justification(s) returned successfully",
  [AlertMessages.readAlertJustificationError]: "Failed to fetch justification(s)",
  [AlertMessages.deleteAlertJustificationSuccess]: "Justification deleted successfully",
  [AlertMessages.deleteAlertJustificationError]: "Failed to delete justification",
  [AlertMessages.alertjustificationNotRegistered]: "Justification not registered in our system",
  [AlertMessages.noAlertJustificationFound]: "No justification found",

  /* region Integrations messages */
  [AlertMessages.invalidDatePeriod]: "Invalid date period"
  /* endregion */
  /* endregion Backend messages */
};
