export enum TimelineVehicleStatesMessages {
  statesTitle = "timelineVehicleStates.statesTitle",
  manometer = "timelineVehicleStates.manometer",
  blockedVehicle = "timelineVehicleStates.blockedVehicle",
  blockingDate = "timelineVehicleStates.blockingDate"
}

export type TranslationTimelineVehicleStatesMessages = {
  [key in TimelineVehicleStatesMessages]: string;
}
