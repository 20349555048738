/* eslint-disable import/prefer-default-export */
import { TranslationFilterMessages, FilterMessages } from "../interfaces";

export const messages: TranslationFilterMessages = {
  [FilterMessages.filtersTitle]: "Filtros",
  [FilterMessages.noVehicles]: "Ningún vehículo en este momento",
  [FilterMessages.noVehiclesLinkedToAPlant]: "Ningún vehículo vinculado a una planta",
  [FilterMessages.noVehiclesLinkedToARegional]: "Ningún vehículo vinculado a una regional",
  [FilterMessages.noVehiclesLinkedToAGroup]: "Ningún vehículo vinculado a un grupo",
  [FilterMessages.noConstruction]: "Ninguna obra en este momento",
  [FilterMessages.selectSomethingToFilter]: "Seleccione algo para filtrar",
  [FilterMessages.selectAtLeastOneVehicleType]: "Seleccione al menos un tipo de vehículo",
  [FilterMessages.concreteMixers]: "Hormigoneras",
  [FilterMessages.pumpTrucks]: "Camiones bomba",
  [FilterMessages.supportVehicles]: "Vehículos de apoyo",
  [FilterMessages.loaders]: "Cargadores",
  [FilterMessages.clearFilters]: "Limpiar filtros",
  [FilterMessages.applyFilters]: "Aplicar filtros",
  [FilterMessages.filterInstructions]: "Filtre la información para obtener mejores resultados",
  [FilterMessages.optionUtcOffset]: "Desviación Horaria (UTC)",

  [FilterMessages.optionVehicleTypes]: "Tipos de vehículo",
  [FilterMessages.optionStartDate]: "Fecha inicial",
  [FilterMessages.optionEndDate]: "Fecha final",
  [FilterMessages.optionVehicles]: "Vehículos",
  [FilterMessages.optionPlants]: "Centrales",
  [FilterMessages.optionConstructions]: "Obras",
  [FilterMessages.optionClients]: "Clientes",
  [FilterMessages.optionAlertTypes]: "Tipos de alerta",
  [FilterMessages.optionDrivers]: "Conductores",
  [FilterMessages.optionRegionals]: "Regionales",
  [FilterMessages.optionGroups]: "Grupos",
  [FilterMessages.optionTravels]: "Viajes",
  [FilterMessages.optionReportType]: "Tipo de informe",
  [FilterMessages.optionVehicleStateTypes]: "Tipos de estado",

  [FilterMessages.applicationVehicleHistory]: "Histórico de vehículos",
  [FilterMessages.applicationAlerts]: "Histórico de alertas",
  [FilterMessages.applicationTravelHistory]: "Histórico de viajes",
  [FilterMessages.applicationCoordinateHistory]: "Histórico de coordenadas",

  [FilterMessages.dateRangeLabel]: "Intervalo",
  [FilterMessages.filterOptions]: "Opciones de filtro",
  [FilterMessages.selectAll]: "Seleccionar todo",
  [FilterMessages.removeAll]: "Eliminar todo",
  [FilterMessages.selectAllFiltered]: "Seleccionar todas las opciones filtradas",
  [FilterMessages.removeAllFiltered]: "Eliminar todas las opciones filtradas",
  [FilterMessages.savedFilters]: "Filtros guardados",
  [FilterMessages.chooseFilterName]: "Elegir nombre del filtro",
  [FilterMessages.filterName]: "Nombre del filtro",
  [FilterMessages.saveNewFilter]: "Guardar selecciones como nuevo filtro",
  [FilterMessages.saveChanges]: "Guardar cambios en el filtro seleccionado",

  // Filter backend messages
  [FilterMessages.noFilterFound]: "No se encontró ningún filtro",
  [FilterMessages.readSuccess]: "Filtros devueltos con éxito",
  [FilterMessages.readError]: "Error al buscar filtros",
  [FilterMessages.createSuccess]: "Filtro creado con éxito",
  [FilterMessages.createError]: "Error al crear filtro",
  [FilterMessages.updateSuccess]: "Filtro actualizado con éxito",
  [FilterMessages.updateError]: "Error al actualizar filtro",
  [FilterMessages.deleteSuccess]: "Filtro eliminado con éxito",
  [FilterMessages.deleteError]: "Error al eliminar filtro"
};
