/* eslint-disable import/prefer-default-export */
import { TranslationMapMessages, MapMessages } from "../interfaces";

export const messages: TranslationMapMessages = {
  [MapMessages.updateVehicleCurrentPosition]: "Actualizar la posición actual del vehículo",
  [MapMessages.errorFetchingAddressData]: "Error al buscar datos de dirección",
  [MapMessages.locationSearchTitle]: "Búsqueda de ubicación",
  [MapMessages.typeLocationLabel]: "Escriba la ubicación",
  [MapMessages.allVehiclesSearchLabel]: "Búsqueda por placa, descripción o cód.",
  [MapMessages.route]: "Ruta",
  [MapMessages.outwardTrip]: "Ida",
  [MapMessages.returnTrip]: "Vuelta",
  [MapMessages.names]: "Nombres",
  [MapMessages.licensePlates]: "Placas",
  [MapMessages.alerts]: "Alertas",
  [MapMessages.states]: "Estados",
  [MapMessages.events]: "Eventos",
  [MapMessages.viewLocation]: "Visualizar ubicación"
};
