export enum EventTypes {
  PARADA = "Parada",
  DESCARREGAMENTO_INDEVIDO = "Descarregamento indevido",
  EXCESSO_DE_VELOCIDADE = "Excesso de velocidade",
  BLOQUEIO = "Bloqueio de veículo",
  VENCIMENTO_DO_CONCRETO = "Vencimento do concreto",
  MANUTENCAO = "Manutenção de veículo",
  DESCARREGAMENTO_POTENCIAL = "Descarregamento potencial",
  EXCESSO_DE_VELOCIDADE_VIAGEM = "Excesso de velocidade definido para a viagem",
  JAMMING_DETECTADO = "Evento de Jammer"
}

export enum EventTypesID {
  PARADA = "05f6912f-e8d7-45ed-9221-232461285e20",
  DESCARREGAMENTO_INDEVIDO = "6d9d85ff-32f2-4fe7-a40e-0460a543000c",
  EXCESSO_DE_VELOCIDADE = "851dc10c-9a70-42dd-8f50-839e92e8acf0",
  BLOQUEIO = "6cdc4ea0-7667-11ed-a1eb-0242ac120002",
  VENCIMENTO_DO_CONCRETO = "827ece16-e505-49eb-a66e-c8fff9ba2cb2",
  MANUTENCAO = "767850d8-941d-426b-9dc6-a8e86110db34",
  DESCARREGAMENTO_POTENCIAL = "7f267e45-6dbe-4cf9-8733-a72afec72786",
  EXCESSO_DE_VELOCIDADE_VIAGEM = "8b914ae4-d7c8-404a-add1-ada6a50fb0a6",
  JAMMING_DETECTADO = "20204d7a-e4fe-47af-8ff6-187bca92f3a3"
}
