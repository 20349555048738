/* eslint-disable max-len */

import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { useSelector } from "react-redux";
import { ReactSVG } from "react-svg";

// region Imports - Assets
import LogoFleet from "@assets/FleetLogo/fleetLogo.svg";
import IconObra from "@assets/stateIcons/Na obra.svg";
import { ReactComponent as ManutencaoIcon } from "@assets/icons/maintenance-vehicle.svg";
import { ReactComponent as AlertIconRed } from "@assets/icons/alerts/create-alert-speed-red.svg";
import { ReactComponent as AlertIconGreen } from "@assets/icons/alerts/create-alert-speed-green.svg";
import { ReactComponent as UnblockVehicleIcon } from "@assets/icons/unblocked-vehicle.svg";
import { ReactComponent as BlockVehicleIcon } from "@assets/icons/blocked-vehicle.svg";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
// endregion Imports - Assets
// region Imports - Utility
import $ from "jquery";
import _ from "lodash";
import { computeDistanceBetween, LatLng } from "@utils/geometry";
import utils from "@utils/useful-functions";
// endregion Imports - Utility
// region Imports - Material-UI
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Typography
} from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// endregion Imports - Material-UI
// region Imports - Languages
import useTranslation from "src/translations/useTranslation";
import {
  GlobalMessages,
  MapMessages,
  TimelineVehicleStatesMessages, TravelAlertFullSpeedMessage,
  VehicleAndTravelDetailsMessages, VehicleMaintenanceMessages
} from "@shared/languages/interfaces";
// region Imports - Constants and Enums
import { MapColors, MapConfig } from "@shared/constants/map.enum";
import { VehicleStatesTypesID } from "@shared/constants/vehicle-states-types.enum";
import { AlertTypesID } from "@shared/constants/alert-types.enum";
import { CircleOptions, MarkerOptions, PolylineOptions } from "@shared/interfaces/map.interface";
import { Travel } from "@shared/interfaces/travel.interface";
import { TravelAnalytics } from "@shared/interfaces/travel-analytics.interface";
import { Vehicle } from "@shared/interfaces/vehicle.interface";
import { VehicleTypes, VehicleTypesID } from "@shared/constants/vehicle-types.enum";
// endregion Imports - Constants and Enums
// region Imports - Hooks
import { useAuth } from "@hooks/useAuth";
import { useToast } from "@hooks/useToast";
// endregion Imports - Hooks
// region Imports - Store
import { ScreenSize } from "@store/ducks/Screen/screen.type";
// endregion Imports - Store
// region Imports - Services
import api from "@services/api";
// endregion Imports - Services
// region Imports - Components
import AlertModalList from "@components/Alert/AlertModalList";
import { MapAnalytics, PopperMarker } from "@components/Map";
import TimeLineVehicleStates from "@components/TimeLines/TimeLineVehicleStates";
import TravelData from "@components/TravelData";
import AggregateTrucksTravelData from "@components/AggregateTrucksTravelData";
import VehicleStatus from "@components/VehicleStatus";
import ModalVehicleBlock from "@components/Modal/VehicleBlock";
import ModalVehicleMaintenance from "@components/Modal/VehicleMaintenance";
import WaterAdditionModalList from "@components/WaterAddition/WaterAdditionModalList";

import ModalSpeedTravel from "@components/Modal/SpeedTravelAlert";
// endregion Imports - Components
// region Imports - Styles
import { Alert as AlertInterface } from "@shared/interfaces/alert.interface";
import { ModalVehicleAndTravelDetailsStylesheet as Stylesheet } from "./style";
// endregion Imports - Styles

// region Interfaces
interface Coord {
  lat: number,
  lng: number
}

interface PolylineCoord {
  going: Coord[],
  returning: Coord[]
}

interface MapFilter {
  [key: string]: {
    class: string,
    title: string,
    status: boolean,
    color: string
  }
}

// endregion Interfaces
// region External Functions

/** Generate an array of MarkerOptions (used by map component to create markers)
 * @param travelDetails Target travel to be analyzed
 * @param travelAnalyticRecords Travel analytic records
 * @param vehicle Vehicle object to get Vehicle States
 * @return A array of MarkerOptions
 */
const generateMarkerOptions = (
  travelDetails: Travel, travelAnalyticRecords: TravelAnalytics[], vehicle: Vehicle
): MarkerOptions[] => {
  // Minimum distance to consider a point as a new marker
  const MIN_DISTANCE = 120;

  // Array of markers options
  const generatedMarkers: MarkerOptions[] = [];

  if (travelAnalyticRecords.length > 0) {
    // Control variable to determine if it is going or returning
    let isGoing = true;

    // Used to compare distance between last point
    let lastMarker: TravelAnalytics = travelAnalyticRecords[0];

    // Create index based on inserted markers
    let index = 0;

    // Count used to add 1 marker and skip others
    let count = 0;

    // Iterate over all travel analytic records
    for (let i = 0; i < travelAnalyticRecords.length; i++) {

      // Get current travel analytic record
      const rec = travelAnalyticRecords[i];

      // Increment index in every loop
      index += 1;

      // Get alert data from vehicle.alerts and merge with rec.alert
      if (rec?.alert) {
        for (let x = 0; x < (travelDetails?.vehicle?.alerts ?? []).length; x++) {
          if (travelDetails?.vehicle?.alerts[x].id_alert === rec.alert.id_alert) {
            rec.alert = { ...rec.alert, ...travelDetails?.vehicle?.alerts[x] };
            break;
          }
        }
      }

      const color: string = isGoing ? MapColors.GOING : MapColors.RETURNING;
      const generateTitle = (idx: number, description = "") => `[${idx}] ${description}\n${utils.formatDateIfHave(
        rec.registration_date, "fullDate"
      )}`;
      const generateInfoContent = (idx: number, going = false) => ReactDOMServer.renderToString(
        <PopperMarker
          index={idx}
          isGoing={going}
          record={rec}
        />
      );

      // Check if pin is in same location of any coord
      let isCenterOfUnloadRadius = false;

      if (travelDetails?.coords) {

        for (const coord of travelDetails.coords) {
          if (coord.latitude === rec?.content?.LAT?.content
            && coord.longitude === rec?.content?.LON?.content) {
            isCenterOfUnloadRadius = true;
            break;
          }
        }
      }

      // Default option. Case reg is not State, Alert or Event, use this exact options to create a marker
      const defaultOptions: MarkerOptions = {
        position: {
          lat: Number.parseFloat(rec?.content?.LAT?.content),
          lng: Number.parseFloat(rec?.content?.LON?.content)
        },
        title: generateTitle(index),
        opacity: 1,
        icon: {
          url: isCenterOfUnloadRadius
            ? utils.getMarkerAccordingVehicleState(VehicleStatesTypesID.DESCARREGANDO)
            : utils.getMarkerDynamically(Number.parseFloat(rec?.content?.CRS?.content ?? "0"), color),
          scaledSize: { width: 14, height: 14 } as google.maps.Size
        },
        visible: false,
        infoContent: generateInfoContent(index, isGoing),
        class: isGoing ? "going" : "returning"
      };

      if (travelAnalyticRecords.length - 1 === i) {

        // Add vehicle icon in last (current) point of same
        generatedMarkers.push({
          ...defaultOptions,
          title: generateTitle(index, vehicle?.type?.description),
          animation: 1,
          opacity: 1,
          icon: {
            ...defaultOptions.icon as google.maps.Icon,
            url: utils.getMarkerAccordingVehicleType(
              vehicle,
              vehicle?.states[vehicle?.states?.length - 1]?.status?.id_vehicle_state_type,
              {
                licensePlate: false,
                name: false
              }
            ),
            scaledSize: { width: 30, height: 45 } as google.maps.Size
          },
          infoContent: generateInfoContent(index, isGoing),
          class: "last_position"
        });

        lastMarker = rec;

      } else if (rec.vehicleState) {

        // If State is Retornando, then change travel to Return
        if (VehicleStatesTypesID.RETORNANDO === rec.vehicleState.status.id_vehicle_state_type) {
          isGoing = false;
        }

        // States Marker
        generatedMarkers.push({
          ...defaultOptions,
          title: generateTitle(index, rec.vehicleState.status.description),
          animation: 2,
          opacity: 1,
          icon: {
            ...defaultOptions.icon as google.maps.Icon,
            url: utils.getMarkerAccordingVehicleState(rec.vehicleState.status.id_vehicle_state_type),
            scaledSize: { width: 30, height: 45 } as google.maps.Size
          },
          infoContent: generateInfoContent(index, isGoing),
          class: "states"
        });

        lastMarker = rec;
      } else if (rec.alert) {

        const markerOptions = {
          ...defaultOptions,
          title: generateTitle(index, rec.alert.type.description),
          animation: 2,
          opacity: 1,
          icon: {
            ...defaultOptions.icon as google.maps.Icon,
            url: utils.getMarkerAccordingVehicleAlert(rec.alert.type.id_alert_type),
            scaledSize: { width: 25, height: 40 } as google.maps.Size
          },
          infoContent: generateInfoContent(index, isGoing),
          class: "alerts"
        };

        // Alert Marker
        generatedMarkers.push(markerOptions);

        lastMarker = rec;
      } else if (rec.event) {

        const markerOptions = {
          ...defaultOptions,
          title: generateTitle(index, rec.event.type.description),
          animation: 2,
          opacity: 1,
          icon: {
            ...defaultOptions.icon as google.maps.Icon,
            url: utils.getMarkerAccordingVehicleEvent(rec.event.type.id_event_type)
              || `http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=${index}|${"#FF9800".replace("#",
                "")}|000000`,
            scaledSize: { width: 25, height: 40 } as google.maps.Size
          },
          infoContent: generateInfoContent(index, isGoing),
          class: "events"
        };

        // Get coords of events when have
        if (rec.event.latitude && rec.event.longitude) {
          markerOptions.position = { lat: Number(rec.event.latitude), lng: Number(rec.event.longitude) };
        }

        // Event Marker
        generatedMarkers.push(markerOptions);

        lastMarker = rec;
      } else if (rec.content?.LAT?.content && rec.content?.LON?.content
        && lastMarker.content?.LAT?.content && lastMarker.content?.LON?.content) {

        const distance = computeDistanceBetween(
          new LatLng(
            Number.parseFloat(rec.content.LAT.content),
            Number.parseFloat(rec.content.LON.content)
          ),
          new LatLng(
            Number.parseFloat(lastMarker.content?.LAT?.content),
            Number.parseFloat(lastMarker.content?.LON?.content)
          )
        );

        if (distance > MIN_DISTANCE && count < 1) {
          defaultOptions.icon = {
            ...defaultOptions.icon as google.maps.Icon,
            anchor: { x: 5, y: 5 } as google.maps.Point
          };

          lastMarker = rec;
          count += 1;
        } else {
          defaultOptions.visible = false;
          defaultOptions.class = "invisible";

          // To reduce number of markers add 1 skip 1
          if (count === 1) count = 0;
          // Need decrement index because marker will not be added
          index -= 1;
        }

        generatedMarkers.push(defaultOptions);
      } else {

        // Default marker (First)
        generatedMarkers.push(defaultOptions);

        lastMarker = rec;
      }
    }
  }

  return generatedMarkers;
};

/** Generate an array of PolylinesOptions (used by map component to trace routes lines)
 * @param markerOptions
 */
const generatePolylinesOptions = (markerOptions: MarkerOptions[]) => {

  // Control variable to determine if it is going or returning
  let isGoing = true;

  // Array of polylines options
  const generatedPolylines: PolylineOptions[] = [];
  const coords: PolylineCoord = { going: [], returning: [] };

  // Iterate over all travel analytic records
  markerOptions.forEach((rec) => {

    // Verify if rec is a vehicle state and the state ir after 'Retornando'
    if (isGoing && rec.class === "returning") isGoing = false;

    coords[`${isGoing ? "going" : "returning"}`].push(rec.position as Coord);
  });

  // Add marker 'Retornando' in 'going' last position, to create a continuous line
  if (coords["returning"]?.length > 0) {
    coords["returning"].unshift(coords["going"][coords["going"].length - 1]);
  }

  // Default options of polylines
  const defaultOptions: PolylineOptions = { strokeOpacity: 0.8, strokeWeight: 8, geodesic: true };

  Object.keys(coords).forEach((key) => {
    generatedPolylines.push({
      ...defaultOptions,
      path: coords[key],
      strokeColor: key === "going" ? MapColors.GOING : MapColors.RETURNING,
      class: key
    });
  });

  return generatedPolylines;
};

/** Generate an array of CircleOptions (used by map component to create circles)
 * @param travel Target travel to be analyzed
 * @return A array of CircleOptions
 */
const generateCirclesOptions = (travel: Travel): CircleOptions[] => {
  const circles: CircleOptions[] = [];

  if (travel) {

    // Create 'Obra' circle radius
    if (travel?.destination) {

      // Create NF address circle radius
      if (travel?.destination?.source_latitude && travel?.destination?.source_longitude) {

        circles.push({
          strokeColor: "#F57C00",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#F57C00",
          fillOpacity: 0.35,
          center: {
            lat: Number.parseFloat(travel?.destination?.source_latitude),
            lng: Number.parseFloat(travel?.destination?.source_longitude)
          },
          radius: Number.parseFloat(`${travel?.destination?.radius}`) || 250
        });
      }

      // Create Destination circle
      if (travel?.destination?.latitude && travel?.destination?.longitude) {

        circles.push({
          strokeColor: utils.getStateColor(VehicleStatesTypesID.EM_OBRA),
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: utils.getStateColor(VehicleStatesTypesID.EM_OBRA),
          fillOpacity: 0.35,
          center: {
            lat: Number.parseFloat(travel.destination?.latitude),
            lng: Number.parseFloat(travel.destination?.longitude)
          },
          radius: Number.parseFloat(`${travel?.destination?.radius}`) || 250
        });
      }
    }

    // Create 'Descarregando' circles
    if (travel?.coords) {
      (travel?.coords || []).forEach((coord) => {
        circles.push({
          strokeColor: utils.getStateColor(VehicleStatesTypesID.EM_OBRA),
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: utils.getStateColor(VehicleStatesTypesID.EM_OBRA),
          fillOpacity: 0.35,
          center: { lat: Number.parseFloat(coord.latitude), lng: Number.parseFloat(coord.longitude) },
          radius: Number.parseFloat(`${travel?.destination?.radius}`) || 250
        });
      });
    }

    // Create 'Locais Confiavel' circles
    if (travel?.vehicle?.states) {

      // Filter to get only trusted places and remove duplicates
      const uniqueTrustedPlaces = _.uniqBy([...travel.vehicle.states]
        .filter((tr) => tr.status.id_vehicle_state_type === VehicleStatesTypesID.EM_LOCAL_CONFIAVEL)
        .map((el) => ({
          latitude: el.location.latitude,
          longitude: el.location.longitude,
          radius: el.location.radius
        })), (unq) => `${unq.latitude};${unq.longitude}`);

      // Create and push circle into "circles array"
      uniqueTrustedPlaces.forEach((el) => {
        circles.push({
          strokeColor: utils.getStateColor(VehicleStatesTypesID.EM_LOCAL_CONFIAVEL),
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: utils.getStateColor(VehicleStatesTypesID.EM_LOCAL_CONFIAVEL),
          fillOpacity: 0.35,
          center: { lat: Number.parseFloat(el.latitude), lng: Number.parseFloat(el.longitude) },
          radius: el.radius || MapConfig.RADIUS
        });
      });

    }

    // Case Origin and End in the same location, create circle with another color
    if (travel?.origin && travel?.end
      && travel?.origin?.latitude === travel?.end?.latitude
      && travel?.origin?.longitude === travel?.end?.longitude) {

      circles.push({
        strokeColor: "#00F0F0",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#00F0F0",
        fillOpacity: 0.35,
        center: { lat: Number.parseFloat(travel.origin.latitude), lng: Number.parseFloat(travel.origin.longitude) },
        radius: Number.parseFloat(`${travel?.origin?.radius}`) || 80
      });

    } else {

      // Create 'Origin' circle radius
      if (travel?.origin) {

        circles.push({
          strokeColor: "#0F0",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#0F0",
          fillOpacity: 0.35,
          center: { lat: Number.parseFloat(travel.origin.latitude), lng: Number.parseFloat(travel.origin.longitude) },
          radius: Number.parseFloat(`${travel?.origin?.radius}`) || 80
        });
      }

      // Create 'End' circle radius
      if (travel?.end) {

        circles.push({
          strokeColor: "#00F",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#00F",
          fillOpacity: 0.35,
          center: { lat: Number.parseFloat(travel.end.latitude), lng: Number.parseFloat(travel.end.longitude) },
          radius: Number.parseFloat(`${travel?.end?.radius}`) || 80
        });
      }
    }
  }

  return circles;
};

/** Generate object of default map filter
 * @param t Used to translate messages
 * @param vehicle Used to analyze if vehicle is "Veiculo de apoio"
 * @return A object of MapFilter
 */
const generateDefaultMapFilter = (t, vehicle?: Vehicle): MapFilter => ({

  going: {
    class: "going",
    title: t(MapMessages.outwardTrip),
    status: vehicle?.type?.id_vehicle_type !== VehicleTypesID.VEICULO_DE_APOIO,
    color: MapColors.GOING
  },
  returning: {
    class: "returning",
    title: t(MapMessages.returnTrip),
    status: vehicle?.type?.id_vehicle_type !== VehicleTypesID.VEICULO_DE_APOIO,
    color: MapColors.RETURNING
  },
  states: { class: "states", title: t(MapMessages.states), status: true, color: "#7E73F8" },
  alerts: { class: "alerts", title: t(MapMessages.alerts), status: true, color: "#FD0013" },
  events: { class: "events", title: t(MapMessages.events), status: true, color: "#A89423" }
});

/** Generate chip components used to show/change filter map
 * @return A object of MapFilter
 * @param mapFilter
 * @param setMapFilter
 */
const generateMapFilterChips = (
  mapFilter: MapFilter, setMapFilter: React.Dispatch<React.SetStateAction<MapFilter>>
) => Object.keys(mapFilter)
  .map((key) => {

    const filter = mapFilter[key];

    return (
      <Chip
        key={`filter-chip-${filter.title}`}
        label={filter.title}
        style={{
          fontWeight: "bold",
          margin: "2px 0px",
          color: filter.status ? "#FAFAFA" : "#9E9E9E",
          backgroundColor: filter.status ? filter.color : "#DDD"
        }}
        onDelete={() => {
          setMapFilter((state) => ({ ...state, [key]: { ...state[key], status: !filter.status } }));
        }}
        {...(!filter.status ? { deleteIcon: <DoneIcon /> } : {})}
      />
    );
  });

// endregion External Functions

type ModalVehicleAndTravelDetailsProps = {
  vehicle: Vehicle;
  travel: Travel;
  open: boolean;
  onClose: () => void;
  historical?: boolean;
  overAll?: boolean;
  onlyView?: boolean;
  isBlocked?: boolean
};
const ModalVehicleAndTravelDetails: React.FC<ModalVehicleAndTravelDetailsProps> = ({
  vehicle,
  travel,
  open,
  onClose,
  historical = false,
  overAll = false,
  onlyView = false,
  isBlocked = vehicle.blocked
}) => {

  // region Hooks
  const { addToast } = useToast();

  const improperDischargeAlerts = useSelector(({ alerts: state }) => state.all.list.filter(
    (alert) => alert.type.id_alert_type === AlertTypesID.DESCARREGAMENTO_INDEVIDO
  ));

  const screen = useSelector(({ screen: state }) => state);

  const { user } = useAuth();

  const { t } = useTranslation();
  // endregion Hooks
  // region States
  const [showMap, setShowMap] = useState(false);
  const [openModalVehicleBlock, setOpenModalVehicleBlock] = useState(false);
  const [openModalVehicleMaintenance, setOpenModalVehicleMaintenance] = useState(false);
  const [openModalCreateAlert, setOpenModalCreateAlertSpeed] = useState(false);

  const [travelAnalyticRecords, setTravelAnalyticRecords] = useState<TravelAnalytics[]>([]);
  const [travelDetails, setTravelDetails] = useState<Travel>({} as Travel);

  const [alerts, setAlerts] = useState<AlertInterface[]>([]);

  const [loadingTravelMap, setLoadingTravelMap] = useState(false);
  const [mapFilter, setMapFilter] = useState(generateDefaultMapFilter(t, vehicle));
  // endregion States
  // region Constants
  const isMobile = screen.size !== ScreenSize.large;

  const isAggregateOrSiloTruck = vehicle.type.description === VehicleTypes.CARRETA_SILO
    || vehicle.type.description === VehicleTypes.CARRETA_AGREGADOS;
  // endregion Constants
  // region Functions

  /** Search details of selected travel to Analyze (just coords)
   * @param travel Target travel to be analyzed
   */
  const analyzeTravelDetail = useCallback(async (travel: Travel) => {

    if (!travel) {
      addToast({
        type: "info",
        title: t(VehicleAndTravelDetailsMessages.noActiveTravel),
        description: t(VehicleAndTravelDetailsMessages.noActiveTravelDescription)
      });
    } else {

      const travelDet: Travel = { ..._.clone(travel), vehicle: { ..._.clone(vehicle) } };
      let alertMessageContent = "";

      // Internal functions
      const FreadTravelAnalytics = async () => (await api.get("travels/get-travel-analytics",
        { params: { idTravel: travel.id_travel, fullAnalytics: false } })).data;
      const FreadTravelCoords = async () => (await api.get(`coords/read/${travel.id_travel}`)).data;

      // Show loading
      setLoadingTravelMap(true);

      try {

        const promise1 = FreadTravelAnalytics();
        const promise2 = travelDet?.destination ? await FreadTravelCoords() : await Promise.resolve();

        const [travelAnalyticsResponse, travelCoordsResponse] = await Promise.all([
          promise1, promise2
        ]);

        // Verify if have some alert in analytics data travel response
        if (travelAnalyticsResponse.status === "success") setTravelAnalyticRecords(travelAnalyticsResponse.result);
        else alertMessageContent += travelAnalyticsResponse.message;

        // Verify if we have Location Coords
        if (travelDet?.destination && travelCoordsResponse?.status === "success") {
          travelDet.coords = travelCoordsResponse.result;
          setTravelDetails(travelDet);
        } else alertMessageContent += travelCoordsResponse?.message ? travelCoordsResponse.message : "";

        // If we have some alert, show message to user
        if (!_.isEmpty(alertMessageContent)) {
          addToast({ type: "info", title: t(GlobalMessages.alert), description: alertMessageContent });
        } else {
          setShowMap(true);
          setTravelDetails(travelDet);
        }

      } catch (error) {
        if (!error.response) addToast({ type: "error", title: t(GlobalMessages.error), description: t(GlobalMessages.connectionNotEstablished) });
        else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });
      } finally {
        setLoadingTravelMap(false);
      }
    }

  }, [addToast, vehicle, t]);

  // Generate all map elements configured based in their types (markers, polylines, circles, filter)
  const markers = useMemo(() => generateMarkerOptions(travelDetails, travelAnalyticRecords, vehicle),
    [travelDetails, travelAnalyticRecords, vehicle]);

  // Generate polylines based on markers
  const polylines = useMemo(() => generatePolylinesOptions(markers), [markers]);

  const circles = useMemo(() => generateCirclesOptions(travelDetails), [travelDetails]);
  const mapFilterChips = useMemo(() => generateMapFilterChips(mapFilter, setMapFilter), [mapFilter, setMapFilter]);

  // Close modal actions
  const handleClose = useCallback(() => {
    onClose();
    setTimeout(() => { setShowMap(false); }, 1000);
  }, [onClose]);

  const handleOpenVehicleBlockModal = useCallback(() => {

    setOpenModalVehicleBlock(true);

  }, []);

  const handleOpenVehicleMaintenanceModal = useCallback(async () => {

    setOpenModalVehicleMaintenance(true);

  }, []);

  const handleOpenCreateAlertSpeedModal = useCallback(async () => {
    setOpenModalCreateAlertSpeed(true);
  }, []);

  const workHeaderContainer = useMemo(() => {

    let description = t(VehicleAndTravelDetailsMessages.noDescriptionRegistered);

    if (travel && travel.description) description = travel.description;

    if (isMobile) {

      return (
        <div className="block vehicle-travel-details__work-header">
          <div className="icon">
            <ReactSVG src={IconObra} />
          </div>
          <div className="content">
            <div className="title">{t(VehicleAndTravelDetailsMessages.construction)}</div>
            <div className="subtitle">
              { description }
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="block vehicle-travel-details__work-header">
        <div className="box">
          <div className="title">{t(VehicleAndTravelDetailsMessages.construction)}</div>
          <div className="text">
            { description }
          </div>
        </div>
      </div>
    );
  }, [isMobile, travel, t]);

  const statusContainer = useMemo(() => {

    if (!historical) {
      return (<VehicleStatus vehicle={vehicle} historical={historical} />);
    }

    return (<></>);
  }, [vehicle, historical]);

  const travelDataContainer = useMemo(() => {

    if (!$.isEmptyObject(travel)) {

      if (isAggregateOrSiloTruck) return (<AggregateTrucksTravelData travel={travel} vehicle={vehicle} />);

      return (<TravelData travel={travel} vehicle={vehicle} />);
    }

    return (<></>);
  }, [travel, vehicle, isAggregateOrSiloTruck]);

  const alertsContainer = useMemo(() => {

    if (vehicle) {
      return (
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography component="div">
              <div className="title">{t(VehicleAndTravelDetailsMessages.alertsTitle)}</div>
              { vehicle.alerts && (
                <div className="qtd">
                  <Badge
                    badgeContent={vehicle.alerts.length || 0}
                    color="error"
                  />
                </div>
              ) }
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography component="div">
              <div className="date">
                { !$.isEmptyObject(vehicle)
                && vehicle.alerts
                && vehicle.alerts.length > 0 ? (
                  <>
                    <div className="title">{t(GlobalMessages.date)}</div>
                    <div className="text">
                      { utils.formatDateIfHave(
                        vehicle?.alerts[0]?.event?.start_date ?? "",
                        "date"
                      ) }
                    </div>
                  </>
                  ) : (
                    <div className="no-event">{t(VehicleAndTravelDetailsMessages.noAlertsOnTravel)}</div>
                  ) }
              </div>
              <AlertModalList alerts={alerts ?? []} onlyView={onlyView} />
            </Typography>
          </AccordionDetails>
        </Accordion>
      );
    }

    return (<></>);
  }, [vehicle, onlyView, t, alerts]);

  const currentLoadContainer = useMemo(() => {

    if (isAggregateOrSiloTruck && vehicle?.current_travel?.service_order) {
      return (
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography component="div">
              <div className="title">{t(VehicleAndTravelDetailsMessages.currentLoad)}</div>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <Typography component="div" className="current-load-information">
                <div className="current-load-information__icon">
                  { utils.getIconAccordingProductType(vehicle?.current_travel?.service_order?.product) }
                </div>
                <div className="current-load-information__details">
                  <span className="current-load-information__details--title">{ utils.getLabelAccordingProductType(
                    vehicle?.current_travel?.service_order?.product
                  ) }
                  </span>
                  <div style={{ display: "flex", gap: "0.25rem" }}>
                    <p>{ vehicle?.current_travel?.service_order?.quantity }</p>
                    <span style={{ color: "#666666" }}>{ vehicle?.current_travel?.service_order?.unit }</span>
                  </div>
                  <div className="current-load-information__details--observation">
                    { vehicle?.current_travel?.service_order?.observation }
                  </div>
                </div>
              </Typography>
            </div>
          </AccordionDetails>
        </Accordion>
      );
    }

    return (<></>);
  }, [vehicle, isAggregateOrSiloTruck, t]);

  const waterAdditionsContainer = useMemo(() => {

    if (vehicle?.type?.id_vehicle_type !== VehicleTypesID.BETONEIRA) return (<></>);

    const totalWaterAddedInTravel = travel?.water_additions?.reduce(
      (acc, curr) => acc + curr.water_added, 0
    );

    return (
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography component="div">
            <div className="title">{t(VehicleAndTravelDetailsMessages.waterAdded)} </div>
            {vehicle.alerts && (
            <div className="qtd">
              <Badge
                badgeContent={travel?.water_additions?.length || 0}
                color="error"
              />
            </div>
            )}
          </Typography>
        </AccordionSummary>
        <div className="subtitle"> <div className="bold"> {t(VehicleAndTravelDetailsMessages.litersAdded)}: &nbsp; </div> {totalWaterAddedInTravel}L </div>
        <AccordionDetails>
          <Typography component="div">
            <div className="date">
              {!$.isEmptyObject(vehicle)
                && travel?.water_additions?.length > 0 ? (
                  <>
                    <div className="title">{t(GlobalMessages.date)}</div>
                    <div className="text">
                      {utils.formatDateIfHave(
                        travel.water_additions[0].start_date ?? "",
                        "date"
                      )}
                    </div>
                  </>
                ) : (
                  <div className="no-event">{t(VehicleAndTravelDetailsMessages.noWaterAdded)}</div>
                )}
            </div>
            <WaterAdditionModalList waterAdditions={travel?.water_additions ?? []} />
          </Typography>
        </AccordionDetails>
      </Accordion>
    );

  }, [vehicle, travel, t]);

  const statesContainer = useMemo(() => {

    if (vehicle) {
      return (<TimeLineVehicleStates vehicle={vehicle} historicalTravel={historical} />);
    }

    return (<></>);
  }, [vehicle, historical]);

  const mapContainer = useMemo(() => {

    if (showMap && markers.length > 0) {
      return (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <MapAnalytics
              mapHeight={400}
              markers={markers}
              polylines={polylines}
              circles={circles}
              filter={Object.keys(mapFilter).map((key) => {
                const fil = mapFilter[key];

                return { class: fil.class, status: fil.status };
              })}
              snapToRoads
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container justify="space-evenly">
              { mapFilterChips }
            </Grid>
          </Grid>
        </Grid>
      );
    }

    return (
      <div className="vehicle-travel-details__map--static">
        { loadingTravelMap
          ? <CircularProgress />
          : (
            <img
              src={LogoFleet}
              className="vehicle-travel-details__map-icon"
              alt={t(MapMessages.viewLocation)}
              onClick={() => { analyzeTravelDetail(travel); }}
              aria-hidden="true"
            />
          ) }
      </div>
    );
  }, [loadingTravelMap, analyzeTravelDetail, travel, markers, polylines, circles, mapFilter, mapFilterChips, showMap, t]);

  const mainContainer = useMemo(() => {

    if (isMobile) {
      return (
        <DialogContentText tabIndex={-1} component="div">
          <div className="container">
            { workHeaderContainer }
          </div>
          <div className="vehicle-travel-details__status">
            { statusContainer }
          </div>
          <div className="container">
            { travelDataContainer }
          </div>
          <div className="container">
            <div className="block events">
              { alertsContainer }
            </div>
          </div>
          <div className="container">
            { (vehicle.type.id_vehicle_type === VehicleTypesID.CARRETA_AGREGADOS
                || vehicle.type.id_vehicle_type === VehicleTypesID.CARRETA_SILO)
              && (
                <div className="block currentLoad">
                  { currentLoadContainer }
                </div>
              ) }
          </div>
          <div className="container">
            <div className="block water-additions blue-background-badge">
              { waterAdditionsContainer }
            </div>
          </div>
          <div className="container">
            <div className="block states">
              <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography component="div">{t(VehicleAndTravelDetailsMessages.travelStatus)}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography component="div">
                    <div className="date">
                      <div className="title">{t(GlobalMessages.date)}</div>
                      <div className="text">
                        { !$.isEmptyObject(vehicle)
                          && utils.formatDateIfHave(
                            vehicle.states[vehicle.states.length - 1]
                              ?.registration_date,
                            "date"
                          ) }
                      </div>
                    </div>
                    { statesContainer }
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
          <div className="container">
            <div className="block vehicle-travel-details__map">
              <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography component="div">{t(VehicleAndTravelDetailsMessages.mapPosition)}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography component="div">
                    { mapContainer }
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </DialogContentText>
      );
    }

    return (
      <Grid container spacing={2} className="vehicle-travel-details__list">
        <Grid item xs={12} md={12} lg={4} className="vehicle-travel-details__item-grid">
          <div className="container vehicle-travel-details__status">
            <div className="block">
              { statusContainer }
            </div>
          </div>
          <div className="container timeline-states">
            <div className="block">
              { statesContainer }
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={12} lg={5} className="vehicle-travel-details__item-grid">
          <div className="container info">
            { workHeaderContainer }
            <div className="block vehicle-travel-details__map">
              { mapContainer }
            </div>
            { travelDataContainer }
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          lg={3}
          className="vehicle-travel-details__item-grid vehicle-travel-details__item-grid--alerts"
        >
          <div className="vehicle-travel-details__events">
            { (vehicle.type.id_vehicle_type === VehicleTypesID.CARRETA_AGREGADOS
                || vehicle.type.id_vehicle_type === VehicleTypesID.CARRETA_SILO)
              && (
                <div className="block">
                  { currentLoadContainer }
                </div>
              ) }
            <div className="block">
              { alertsContainer }
            </div>
            <div className="block blue-background-badge">
              { waterAdditionsContainer }
            </div>
          </div>
        </Grid>
      </Grid>
    );
  }, [
    isMobile,
    vehicle,
    statusContainer,
    travelDataContainer,
    alertsContainer,
    waterAdditionsContainer,
    statesContainer,
    mapContainer,
    workHeaderContainer,
    currentLoadContainer,
    t
  ]);

  // Fill maximum_speed_allowed vehicle into alerts
  useEffect(() => {
    if (!vehicle?.alerts) return;
    const alerts = vehicle.alerts.map((alert) => ({
      ...alert,
      vehicle: {
        ...vehicle,
        maximum_speed_allowed: vehicle.maximum_speed_allowed ?? alert.vehicle?.maximum_speed_allowed
      }
    }));

    setAlerts(alerts);
  }, [vehicle]);

  // endregion Functions
  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
      scroll="paper"
      fullWidth
      maxWidth="lg"
      className={`vehicle-travel-details__dialog ${overAll ? "vehicle-travel-details__dialog--overAll" : ""}`}
    >
      <Stylesheet isMobile={isMobile} isBlocked={isBlocked}>
        <div className="vehicle-travel-details__header">
          <div className="vehicle-travel-details__title">
            <div
              className="icon"
              style={{ backgroundColor: utils.getVehicleIconColor(vehicle, user, improperDischargeAlerts) }}
            >
              { utils.getVehicleIconAccordingTypeAndAlert(vehicle, improperDischargeAlerts) }
            </div>
            <div className="content">
              <div className="content__title">
                { vehicle.code } - { vehicle.description } - { isBlocked
                  ? <span style={{ color: "#F00", fontWeight: "bold" }}> {t(TimelineVehicleStatesMessages.blockedVehicle)}</span>
                  : (vehicle.license_plate) }
              </div>
              { vehicle?.current_location && (
                <div className="content__subtitle">
                  {t(VehicleAndTravelDetailsMessages.allocation).toUpperCase()}:
                  { ` ${vehicle.current_location.address}, ${
                    vehicle.current_location.number || ""
                  }
                      - ${vehicle.current_location.district || ""} | ${
                    vehicle.current_location.county
                  } - ${vehicle.current_location.uf}` }
                </div>
              ) }
            </div>
          </div>
          <div className="actions">
            { vehicle.current_travel && !travel.alert_maximum_speed && (
              <Button
                className="create_alert_button"
                startIcon={<AlertIconGreen width="2rem" />}
                onClick={() => handleOpenCreateAlertSpeedModal()}
              >
                {t(TravelAlertFullSpeedMessage.createAlertSpeedTravel)}
                <br />
                {t(TravelAlertFullSpeedMessage.speed)}
              </Button>
            )}
            { vehicle.current_travel && travel.alert_maximum_speed && (
            <Button
              className="show_speed_alert_button"
              style={{ backgroundColor: vehicle.current_speed && vehicle.current_speed > travel.alert_maximum_speed ? "red" : "green", width: "auto", textTransform: "none" }}
              startIcon={vehicle.current_speed && vehicle.current_speed > travel.alert_maximum_speed ? <AlertIconRed width="3rem" /> : <AlertIconGreen width="3rem" />}
              onClick={() => handleOpenCreateAlertSpeedModal()}
              variant="contained"
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div>
                  <span style={{ fontSize: "larger" }}>{vehicle.current_speed}</span>
                  <PlayArrowIcon />
                  <span style={{ fontSize: "larger" }}>{travel.alert_maximum_speed}</span>
                  km/h
                </div>
                <p style={{ color: "white" }}>
                  {vehicle.current_speed && vehicle.current_speed > travel.alert_maximum_speed
                    ? `${t(GlobalMessages.speed)} ${t(GlobalMessages.exceededPT)} ` : `${t(GlobalMessages.speed)} Nominal`}
                </p>
              </div>
            </Button>
            )}

            <Button
              className="maintenance_button"
              startIcon={<ManutencaoIcon width="2rem" />}
              onClick={() => handleOpenVehicleMaintenanceModal()}
              variant="contained"
            >
              {t(VehicleMaintenanceMessages.maintenance)}
            </Button>
            { user.allowed_vehicle_block && vehicle.hardwares?.[0]?.block_capable && (
              <div>
                <Button
                  className="block_button"
                  startIcon={isBlocked
                    ? <UnblockVehicleIcon width="2rem" />
                    : <BlockVehicleIcon width="2rem" />}
                  onClick={() => handleOpenVehicleBlockModal()}
                  variant="contained"
                >
                  { isBlocked ? t(VehicleAndTravelDetailsMessages.unlockVehicle) : t(VehicleAndTravelDetailsMessages.lockVehicle) }
                </Button>
              </div>
            ) }
          </div>
        </div>

        <DialogContent
          dividers
          className={`vehicle-travel-details__content vehicle-travel-details__content--${isMobile
            ? "mobile" : "desktop"}`}
        >
          { mainContainer }
        </DialogContent>
        <DialogActions className="vehicle-travel-details__footer">
          <Button disableRipple onClick={() => handleClose()} color="primary">
            {t(GlobalMessages.close)}
          </Button>
        </DialogActions>
        <ModalVehicleBlock
          open={openModalVehicleBlock}
          onClose={() => {
            setOpenModalVehicleBlock(false);
            handleClose();
          }}
          vehicle={vehicle}
          isBlocked={isBlocked}
        />
        <ModalVehicleMaintenance
          open={openModalVehicleMaintenance}
          onClose={() => {
            setOpenModalVehicleMaintenance(false);
            handleClose();
          }}
          vehicle={vehicle}
          isInMaintenance={vehicle.maintenance}
        />

        <ModalSpeedTravel
          open={openModalCreateAlert}
          onClose={() => {
            setOpenModalCreateAlertSpeed(false);
            handleClose();
          }}
          travel={travel}
          vehicle={vehicle}
        />
      </Stylesheet>
    </Dialog>
  );
};

export {
  ModalVehicleAndTravelDetails
};
