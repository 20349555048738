import styled, { css } from "styled-components";

export const Container = styled.button`
  background: #D52924;
  height: 48px;
  border-radius: 4px;
  border: 0;
  padding: 0 16px;
  color: #fff;
  width: 160px;
  font-size: 20px;
  font-weight: 500;
  transition: background-color 0.2s;

  ${(props) => (!props.disabled
    && css`
      :hover {
        background: rgb(213 41 36 / 0.75);
      }
    `)
};

  ${(props) => (props.disabled
    && css`
      opacity: 0.7;
      cursor: auto;
      background: rgb(213 41 36 / 0.6);
    `)
};

`;
