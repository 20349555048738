import * as dateFns from "date-fns";
import { TLanguages } from "../languages/languageData";

/** Format hour meter in hours and minutes
 * @param hourMeter Hour meter value in minutes
 */
export function formatHourMeter(
  hourMeter?: number
): string {

  if (!hourMeter && hourMeter !== 0) return "";

  const total = (hourMeter / 60);

  const hour = Math.floor(total);
  const decimalPart = total - hour;

  if (decimalPart === 0) return `${hour}h`;

  const minutes = Math.floor(decimalPart * 60);

  if (hour === 0) return `${minutes}m`;

  return `${hour}h ${minutes}m`;
}

/** Format odometer in meter to kilometer
 * @param odometer Odometer value in meter
 */
export function formatOdometer(
  odometer: number,
  appendKM = true,
  decimalSeparator: "." | "," = ","
): string {
  let km = (odometer / 1000).toFixed(1);

  if (decimalSeparator === ",") km = km.replace(".", ",");

  if (appendKM) return `${km} KM`;

  return km;
}

/**
 * Format date and hour
 * @param date Date or Hour or Interval
 * @param type Type of first parameter
 * @param language Language to format date
 */
export function formatDateIfHave(
  date: Date,
  type: "fullDateWithoutYear" | "fullDateWithoutSeconds",
  language?: TLanguages
): string | Date {

  // Define formats according to language
  const fullDateWithoutYearFormat = language === "en-US" ? "MM/dd HH:mm:ss" : "dd/MM HH:mm:ss";
  const fullDateWithoutSecondsFormat = language === "en-US" ? "MM/dd/yyyy HH:mm" : "dd/MM/yyyy HH:mm";

  if (!date) return "";

  switch (type) {
  case "fullDateWithoutYear":
    return dateFns.format(new Date(date), fullDateWithoutYearFormat);
  case "fullDateWithoutSeconds":
    return dateFns.format(new Date(date), fullDateWithoutSecondsFormat);
  default:
    return "";
  }
}
