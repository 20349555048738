import React, {
  createContext, useContext, useCallback, useState
} from "react";
import { uuid } from "uuidv4";
import $ from "jquery";

import ToastContainer from "../components/Toast/ToastContainer";

export interface ToastMessage {
  id: string;
  type?: "success" | "error" | "info";
  title: string;
  duration?: number;
  description?: string;
}

interface ToastContextData {
  addToast(message: Omit<ToastMessage, "id">): void;
  removeToast(id: string): void;
}

const ToastContext = createContext<ToastContextData>({} as ToastContextData);

const ToastProvider: React.FC = ({ children }) => {

  const [messages, setMessages] = useState<ToastMessage[]>([]);

  const addToast = useCallback(
    ({ type, title, description, duration }: Omit<ToastMessage, "id">) => {

      const id = uuid();
      const toast = {
        id,
        type,
        title,
        duration,
        description
      };

      if ($(".toastMessageGlobal").length) {
        $(".toastMessageGlobal").fadeOut(500);
        setTimeout(() => { setMessages((state) => [...state, toast]); }, 500);
      } else setMessages((state) => [...state, toast]);
    },
    []
  );

  const removeToast = useCallback((id: string) => {
    setMessages((state) => state.filter((message) => message.id !== id));
  }, []);

  return (
    <ToastContext.Provider value={{ addToast, removeToast }}>
      {children}
      <ToastContainer messages={messages} />
    </ToastContext.Provider>
  );
};

function useToast(): ToastContextData {

  const context = useContext(ToastContext);

  if (!context) throw new Error("useToast must be used within a ToastProvider");

  return context;
}

export { ToastProvider, useToast };
