import styled from "styled-components";

interface ContainerProps {
    platform: "mobile" | "desktop" | undefined;
}

const VehicleContainer = styled.div<ContainerProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    
    width: 100%;
        
    font-size: ${props => props.platform === "mobile" ? 10 : 13}px;
    line-height: ${props => props.platform === "mobile" ? 12 : 15}px;
    
    div {
        display: flex;
        align-items: center;
        
        span {
        font-weight: 700;
        }
        
        p {
            color: #666666
        }
    }

    .iconContainer {
        width: 20px;
        height: 20px;
        border-radius: 10px;
        background-color: #64DD17;
        
        display: flex;
        align-items: center;
        justify-content: center;
        
        margin-right: 5px;
        
        svg {
            width: 14px;
        }
    }
    
    .infoContainer {
        width: 100%;
        gap: 4px;
    }
`

export default {VehicleContainer}