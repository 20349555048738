/* eslint-disable camelcase */
/* eslint-disable no-shadow */

import { Location } from "@shared/interfaces/location.interface";
import { LocationType } from "@shared/interfaces/location-type.interface";
import { Regional } from "@shared/interfaces/regional.interface";

// Action types
export enum LocationsFormTypes {
  SET_LOCATIONS_FORM = "@locationsForm/SET_LOCATIONS_FORM",
}

// Data Types
export interface Coord { lat: number, lng: number }
export interface State { description: string; abbreviation: string; }
export interface FieldsValues { cep: string, type: LocationType, uf: State, coord: Coord, regional: Regional }
export interface ActionsForm { type: "create" | "read" | "update" | "delete", response: Location }
export const states: State[] = [
  { description: "Acre", abbreviation: "AC" },
  { description: "Alagoas", abbreviation: "AL" },
  { description: "Amapá", abbreviation: "AP" },
  { description: "Amazonas", abbreviation: "AM" },
  { description: "Bahia", abbreviation: "BA" },
  { description: "Ceará", abbreviation: "CE" },
  { description: "Distrito Federal", abbreviation: "DF" },
  { description: "Espírito Santo", abbreviation: "ES" },
  { description: "Goiás", abbreviation: "GO" },
  { description: "Maranhão", abbreviation: "MA" },
  { description: "Mato Grosso", abbreviation: "MT" },
  { description: "Mato Grosso do Sul", abbreviation: "MS" },
  { description: "Minas Gerais", abbreviation: "MG" },
  { description: "Pará", abbreviation: "PA" },
  { description: "Paraíba", abbreviation: "PB" },
  { description: "Paraná", abbreviation: "PR" },
  { description: "Pernambuco", abbreviation: "PE" },
  { description: "Piauí", abbreviation: "PI" },
  { description: "Rio de Janeiro", abbreviation: "RJ" },
  { description: "Rio Grande do Norte", abbreviation: "RN" },
  { description: "Rio Grande do Sul", abbreviation: "RS" },
  { description: "Rondônia", abbreviation: "RO" },
  { description: "Roraima", abbreviation: "RR" },
  { description: "Santa Catarina", abbreviation: "SC" },
  { description: "São Paulo", abbreviation: "SP" },
  { description: "Sergipe", abbreviation: "SE" },
  { description: "Tocantins", abbreviation: "TO" }
];

// State type
export interface LocationsFormState {
  open: boolean;
  type: "details" | "update" | "register";
  data: Location;
  fieldsValues: FieldsValues;
  googleAddressData: google.maps.GeocoderResult;
  externalComponent: boolean;
  actions: ActionsForm;
}
