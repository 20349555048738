/* eslint-disable import/prefer-default-export */
import { TranslationHelpMessages, HelpMessages } from "../interfaces";

export const messages: TranslationHelpMessages = {
  [HelpMessages.titleColors]: "Cores",
  [HelpMessages.titleIcons]: "Ícones",

  [HelpMessages.subtitleOperationalStates]: "Estados operacionais",
  [HelpMessages.subtitleSituationalStates]: "Estados situacionais",
  [HelpMessages.subtitleAlerts]: "Alertas",

  [HelpMessages.descriptionOperationalStatesGoal]: "Reflete a condição atual do veículo no contexto de produção.",
  [HelpMessages.descriptionOperationalStatesUtilization]: "Estas cores são utilizadas nas colunas de dashboards e"
  + " ícones de veículos nos mapas.",
  [HelpMessages.descriptionSituationalStatesGoal]: "Indica a situação atual do veículo.",
  [HelpMessages.descriptionSituationalStatesUtilization]: "Estas cores são apresentadas na cor de fundo do ícone de"
  + " veículos dentro dos cartões.",

  [HelpMessages.blockedOrImproperDischarge]: "Bloqueado ou contém descargas indevidas na viagem atual",
  [HelpMessages.active]: "Veículo ativo",
  [HelpMessages.hasAlerts]: "Contém alertas na viagem atual",
  [HelpMessages.noCommunication]: "Veículo sem conexão com a plataforma"

};
