/* eslint-disable import/prefer-default-export */
import {
  TranslationGlobalMessages,
  GlobalMessages
} from "../interfaces";

export const messages: TranslationGlobalMessages = {
  [GlobalMessages.dialogLandscape]: "Coloque o celular na posição horizontal.",
  [GlobalMessages.loading]: "Carregando...",
  [GlobalMessages.noNameRegistered]: "Sem nome cadastrado",
  [GlobalMessages.actionNotPermitted]: "Ação não permitida",
  [GlobalMessages.noAccess]: "Você não possui acesso a plataforma. Por favor, contate o suporte Topcon.",
  [GlobalMessages.success]: "Sucesso!",
  [GlobalMessages.alert]: "Alerta!",
  [GlobalMessages.error]: "Erro",
  [GlobalMessages.connectionNotEstablished]: "Conexão não estabelecida",
  [GlobalMessages.close]: "Fechar",
  [GlobalMessages.confirm]: "Confirmar",
  [GlobalMessages.confirmTravel]: "Deseja confirmar nova velocidade?",
  [GlobalMessages.model]: "Modelo",
  [GlobalMessages.speed]: "Velocidade",
  [GlobalMessages.fullSpeed]: "Velocidade máxima",
  [GlobalMessages.duration]: "Duração",
  [GlobalMessages.address]: "Endereço",
  [GlobalMessages.travelDetails]: "Detalhes da viagem",
  [GlobalMessages.registerLocation]: "Cadastrar local",
  [GlobalMessages.required]: "Obrigatório",
  [GlobalMessages.date]: "Data",
  [GlobalMessages.start]: "Início",
  [GlobalMessages.time]: "Hora",
  [GlobalMessages.end]: "Fim",
  [GlobalMessages.noData]: "Sem dados",
  [GlobalMessages.noRegisteredClients]: "Sem clientes cadastrados",
  [GlobalMessages.type]: "Tipo",
  [GlobalMessages.vehicle]: "Veículo",
  [GlobalMessages.driver]: "Motorista",
  [GlobalMessages.observations]: "Observações",
  [GlobalMessages.register]: "Cadastrar",
  [GlobalMessages.yes]: "Sim",
  [GlobalMessages.no]: "Não",
  [GlobalMessages.noClientRegistered]: "Sem cliente cadastrado",
  [GlobalMessages.quantity]: "Quantidade",
  [GlobalMessages.save]: "Salvar",
  [GlobalMessages.active]: "Ativo",
  [GlobalMessages.inactive]: "Inativo",
  [GlobalMessages.selectAll]: "Selecionar tudo",
  [GlobalMessages.search]: "Pesquisar",
  [GlobalMessages.apply]: "Aplicar",
  [GlobalMessages.other]: "Outro",
  [GlobalMessages.internalErrorMessage]: "Erro interno, entre em contato com nosso suporte",
  [GlobalMessages.alreadyRegistered]: "já cadastrado",
  [GlobalMessages.notRegistered]: "não cadastrado",
  [GlobalMessages.verifyFieldError]: "Falha ao verificar campo",
  [GlobalMessages.wrongIDFormat]: "ID(s) não se encontra(m) no formato correto",
  [GlobalMessages.filter]: "Filtro",
  [GlobalMessages.edit]: "Editar",
  [GlobalMessages.exceeded]: "Excedido ",
  [GlobalMessages.exceededPT]: "Excedida ",
  [GlobalMessages.exceededBy]: "Excedido em ",
  [GlobalMessages.onTheWay]: "a caminho",
  [GlobalMessages.invalidFilter]: "Filtro inválido"

};
