import {
  Column,
  CreateDateColumn,
  Entity, JoinColumn,
  ManyToOne,
  OneToMany,
  PrimaryGeneratedColumn,
  UpdateDateColumn
} from "typeorm";
import { Location } from "./reg_locations.entity";
import { Group } from "./reg_groups.entity";

@Entity("reg_regionals")
export class Regional {

  @PrimaryGeneratedColumn("uuid")
    id_regional?: string;

  @Column({ type: "varchar", nullable: false, unique: true })
    code: string;

  @Column({ type: "varchar", nullable: false })
    name: string;

  @Column({ type: "boolean", default: true })
    active?: boolean;

  @Column({ type: "varchar", nullable: true, unique: true })
    external_id?: string;

  @CreateDateColumn({ default: "(now() at time zone 'utc')" })
    registration_date?: Date;

  @UpdateDateColumn({ default: "(now() at time zone 'utc')" })
    updated_date?: Date;

  @ManyToOne(() => Group, (group) => group.id_group, { nullable: true })
  @JoinColumn({ name: "id_group_fk" })
    group?: Group;

  @OneToMany(() => Location, (location) => location.regional)
    locations: Location[];

  GetLabelName?(): string {
    return `${this.code} - ${this.name}`;
  }

}
