import * as dateFns from "date-fns";
import * as Yup from "yup";
import * as _ from "lodash";
import * as documentValidator from "cpf-cnpj-validator";

import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactDOMServer from "react-dom/server";
import Loading from "react-fullscreen-loading";
import InputMask from "react-input-mask";

import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText,
  DialogTitle, FormControlLabel, Grid } from "@material-ui/core";

import { Checkbox, TextField } from "unform-material-ui";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";

import { Driver } from "@shared/interfaces/driver.interface";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { Hardware } from "@shared/interfaces/hardware.interface";
import { HardwareTypes } from "@shared/constants/hardware-types.enum";
import NewDataTable, { DataTableActions, DataTableButtons, DataTableColumns } from "@molecules/NewDataTable";
import utils from "@utils/useful-functions";
import { useSelector } from "react-redux";
import { ScreenPlatform } from "@store/ducks/Screen/screen.type";
import useTranslation from "src/translations/useTranslation";
import {
  DataTableMessages,
  DriverModuleMessages,
  GlobalMessages,
  LocationsModuleMessages,
  MenuMessages,
  ToastMessages
} from "@shared/languages/interfaces";
import { useAuth } from "@hooks/useAuth";
import api from "../../../services/api";
import getValidationErrors from "../../../hooks/getValidationErrors";
import { useToast } from "../../../hooks/useToast";

import Header from "../../../components/Header";
import ButtonTable from "../../../components/Button/ButtonTable";
import DialogConfirmAction from "../../../components/Dialog/ConfirmAction";

import { Container, ContainerModalFormDriver } from "./styles";

interface ValidateFields { cpf: boolean; re: boolean; }

const Drivers: React.FC = () => {

  /* region States */
  const [loadingDriverList, setLoadingDriverList] = useState(false);
  const [loadingValidateFieldCpf, setLoadingValidateFieldCpf] = useState(false);
  const [loadingValidateFieldRe, setLoadingValidateFieldRe] = useState(false);
  const [openOptionsIbuttons, setOpenOptionsIbuttons] = useState(false);
  const [optionsIbuttons, setOptionsIbuttons] = useState<Hardware[]>([] as Hardware[]);
  const loadingOptionsIbuttons = openOptionsIbuttons && optionsIbuttons.length === 0;
  const [activeOptionIbutton, setActiveOptionIbutton] = useState<Hardware>({} as Hardware);

  const [drivers, setDrivers] = useState<Driver[]>([] as Array<Driver>);
  const [driverDetails, setDriverDetails] = useState<Driver>({} as Driver);

  const [openDialogConfirmDelete, setOpenDialogConfirmDelete] = useState(false);
  const [openModalFormDriver, setOpenModalFormDriver] = useState(false);

  const [modalType, setModalType] = useState<"details" | "update" | "register">("details");

  const [table, setTable] = useState<DataTables.Api>({} as DataTables.Api);
  const [tableClickedRow, setTableClickedRow] = useState<JQuery<HTMLTableRowElement>>({} as JQuery<HTMLTableRowElement>);

  const [activeCheckbox, setActiveCheckbox] = useState<boolean>(true);
  const [validateFields, setValidateFields] = useState<ValidateFields>({ cpf: false, re: false });
  /* endregion States */
  /* region Hooks */
  const { addToast } = useToast();
  const { user } = useAuth();
  const { t, i18n } = useTranslation();
  const { screen } = useSelector((screen) => screen);
  /* endregion Hooks */
  /* region Form Validation */
  const formRef = useRef<FormHandles>(null);
  /** Validations of unique fields
   */
  const validations = {

    validateFieldError: (fieldName: string) => {
      if (formRef.current?.getFieldError(fieldName)?.length) formRef.current?.setFieldError(fieldName, "");
    },
    validateCpf: async (cpf: string) => {

      setValidateFields({ ...validateFields, cpf: false });

      // CPF completed
      if (cpf.length === 14 && cpf !== driverDetails.cpf) {

        try {

          setLoadingValidateFieldCpf(true);

          // Verify if CPF is valid
          if (!documentValidator.cpf.isValid(cpf)) formRef.current?.setFieldError("cpf", t(DriverModuleMessages.invalidCPF));
          else {

            // Verify if CPF is already registered
            const { data } = await api.get(`drivers/verify-unique-field/cpf/${cpf}`);

            if (data.status === "alert") formRef.current?.setFieldError("cpf", t(DriverModuleMessages.invalidUniqueCPF));
            else formRef.current?.setFieldError("cpf", "");

          }

          setValidateFields({ ...validateFields, cpf: true });

        } catch (error) {
          if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
          else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });
        } finally {
          setLoadingValidateFieldCpf(false);
        }

      } else formRef.current?.setFieldError("cpf", "");

    },
    validateRe: async (re: string) => {

      setValidateFields({ ...validateFields, re: false });

      // Validate re just have 3 or more characters in on blur
      if (re.trim().length >= 3 && re.trim() !== driverDetails.re) {

        try {

          setLoadingValidateFieldRe(true);

          // Verify if RE is already registered
          const { data } = await api.get(`drivers/verify-unique-field/re/${re.trim()}`);

          if (data.status === "alert") formRef.current?.setFieldError("re", t(DriverModuleMessages.invalidUniqueRE));
          else formRef.current?.setFieldError("re", "");

          setValidateFields({ ...validateFields, re: true });

        } catch (error) {
          if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
          else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });
        } finally {
          setLoadingValidateFieldRe(false);
        }
      }

    },
    validateForm: async (formData: Driver, action: "details" | "register" | "update") => {

      try {

        // Revalidate unique fields (If not validate yet)
        if (!validateFields.cpf && formData.cpf.trim().length) await validations.validateCpf(formData.cpf);
        if (!validateFields.re && formData.re.trim().length) await validations.validateRe(formData.re);

        // Define the validation types
        const schema = Yup.object().shape({
          name: Yup.string().trim().required(t(DriverModuleMessages.formRequiredName)),
          cpf: Yup.string().trim().test("validateCpf", formRef.current?.getFieldError("cpf"),
            () => !(formRef.current?.getFieldError("cpf")?.length))
            .min(14, t(DriverModuleMessages.formIncompleteDocument))
            .required(t(DriverModuleMessages.formRequiredDocument)),
          re: Yup.string().trim().test("validateRe", formRef.current?.getFieldError("re"),
            () => !(formRef.current?.getFieldError("re")?.length))
        });

        // Validate inputs
        await schema.validate(formData, { abortEarly: false });

        // Register or update driver (According action selected)
        if (action === "register") await createDriver(formData);
        else await updateDriver(formData);

      } catch (error) {
        formRef.current?.setErrors(getValidationErrors(error));
      }
    }
  };
  /* endregion Form Validation */
  /* region Constants */
  const dataTableSettings: DataTables.Settings = {
    order: [[0, "asc"]],
    columnDefs: [{ className: "dt-center", targets: -1 }]
  };
  const dataTableActions: DataTableActions[] = [
    {
      ref: ".modules-driver-list-details",
      callback: (rowData: Driver) => handleDriverDetails(rowData)
    },
    {
      ref: ".modules-driver-list-delete",
      callback: (rowData: Driver) => handleDriverDelete(rowData)
    }
  ];
  const dataTableColumns: DataTableColumns[] = [
    { // Nome
      title: t(DriverModuleMessages.fullname),
      data: (driver: Driver) => driver.name,
      filterable: true,
      defaultContent: "",
      propertyName: "name"
    },
    { // RE
      title: t(DriverModuleMessages.re),
      data: (driver: Driver) => driver.re || "",
      filterable: true,
      defaultContent: "",
      propertyName: "re"
    },
    { // CPF
      title: t(DriverModuleMessages.document),
      data: (driver: Driver) => driver.cpf,
      filterable: true,
      defaultContent: "",
      propertyName: "cpf"
    },
    { // CNH
      title: t(DriverModuleMessages.licenseNumber),
      data: (driver: Driver) => driver.license_number || "",
      filterable: true,
      defaultContent: "",
      propertyName: "license_number"
    },
    { // Data de expiração
      title: t(DriverModuleMessages.licenseExpiredDate),
      data: (driver: Driver) => driver.license_expired_date,
      render: (data, type) => (data
        ? utils.formatDateIfHave(new Date(data), "fullDate")
        : ""),
      filterable: true,
      defaultContent: "",
      propertyName: "license_expired_date"
    },
    { // Ibutton
      title: t(DriverModuleMessages.iButton),
      data: (driver: Driver) => driver.hardware?.dev_id || "",
      filterable: false,
      defaultContent: "",
      propertyName: "dev_id"
    },
    { // Ativo
      title: t(DataTableMessages.status),
      data: (driver: Driver) => (
        ReactDOMServer.renderToString(
          <b style={{ color: driver.active ? "rgb(70, 193, 125)" : "rgb(230, 74, 25)" }}>
            {driver.active ? t(GlobalMessages.active).toUpperCase() : t(GlobalMessages.inactive).toUpperCase()}
          </b>
        )
      ),
      filterable: true,
      propertyName: "active"
    },
    { // Ações
      title: t(DataTableMessages.actions),
      orderable: false,
      searchable: false,
      data: () => ReactDOMServer.renderToString(
        <Grid container spacing={1}>
          <Grid item xs sm md lg xl>
            <ButtonTable className="action-button modules-driver-list-details"><FormatListBulletedIcon /></ButtonTable>
          </Grid>
          <Grid item xs sm md lg xl>
            <ButtonTable className="action-button modules-driver-list-delete"><DeleteForeverIcon /></ButtonTable>
          </Grid>
        </Grid>
      ),
      width: "130px",
      filterable: false
    }
  ];
  const dataTableButtons: DataTableButtons[] = [
    {
      name: t(DataTableMessages.buttonsAddNew),
      key: "add",
      callback: () => FhandleCreateDriver()
    },
    {
      name: t(DataTableMessages.buttonsRefresh),
      key: "refresh",
      callback: () => readDrivers()
    },
    {
      name: t(DataTableMessages.buttonsPrint),
      key: "print",
      callback: () => utils.clickButtonDomElement("button-print"),
      extend: "print",
      className: "button-print",
      exportOptions: {
        columns: "th:not(:last-child)"
      }
    },
    {
      name: t(DataTableMessages.buttonsExport),
      callback: () => utils.clickButtonDomElement("button-export"),
      extend: "csv",
      key: "export",
      fieldSeparator: ";",
      className: "button-export",
      filename: `relatorio_motoristas_${new Date().toISOString().split("T")[0]}`,
      exportOptions: {
        columns: "th:not(:last-child)"
      }
    }
  ];

  /* endregion Constants */

  /* region Functions */
  // Actions drivers list
  const handleDriverDetails = useCallback((driver: Driver) => {

    setDriverDetails(driver);
    setActiveCheckbox(driver.active);
    setValidateFields({ cpf: true, re: true });

    // Optional fields
    if (driver?.hardware) setActiveOptionIbutton(driver.hardware);

    setModalType("details");

    setOpenModalFormDriver(true);

  }, []);
  const handleDriverDelete = useCallback((driver: Driver) => {

    setDriverDetails(driver);

    setOpenDialogConfirmDelete(true);

  }, []);

  // Actions close dialogs and modals
  const handleCloseModalForm = useCallback(() => {

    setDriverDetails({} as Driver);
    setValidateFields({ cpf: false, re: false });
    setActiveOptionIbutton({} as Hardware);

    setOpenModalFormDriver(false);

  }, []);

  /** Check what modal type to show corresponding values and actions
   * @param detailValue
   * @param updateValue
   * @param registerValue
   */
  const defineValueAccordingModalType = useCallback((detailValue, updateValue, registerValue) => {

    switch (modalType) {
      case "details": return detailValue;
      case "update": return updateValue;
      case "register": return registerValue;
      default: return "S/N";
    }
  }, [modalType]);

  /** Get all drivers data
   */
  const readDrivers = useCallback(async () => {

    try {

      // Get all drivers
      setLoadingDriverList(true);
      const { data } = await api.get("drivers/read");

      if (data.status === "success") setDrivers(data.result);
      else addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

    } catch (error) {

      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

    } finally {
      setLoadingDriverList(false);
    }

  }, [addToast, t]);

  /** Create a driver
   * @param driver Driver to create
   */
  const createDriver = useCallback(async (driver: Driver) => {

    try {

      let insertDriver = { ...driver };

      // Remove properties without value
      Object.keys(insertDriver).forEach((key) => {
        if (insertDriver[key].length <= 0) delete insertDriver[key];
      });

      // Set active Ibutton option
      if (insertDriver.hardware) insertDriver.hardware = activeOptionIbutton;

      // Create a driver
      setLoadingDriverList(true);
      const { data } = await api.post("drivers/create", insertDriver);

      if (data.status === "success") {

        handleCloseModalForm(); // Close modal
        addToast({ type: "success", title: t(ToastMessages.success), description: data.message });

        insertDriver = data.result;

        if (!drivers.length) readDrivers();
        else {

          // Add inserted driver in the table
          table.row.add(insertDriver);
          table.draw();
        }

        // Search the inserted driver and list
        table.search(driver.cpf).draw();

      } else addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

    } catch (error) {

      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

    } finally {
      setLoadingDriverList(false);
    }

  }, [addToast, table, drivers, readDrivers, handleCloseModalForm, activeOptionIbutton, t]);

  /*
   * Open modal to create new driver
   * */
  const FhandleCreateDriver = () => {
    setModalType("register");
    setOpenModalFormDriver(true);
  };
  /** Update a driver
   * @param driver Driver to update
   */
  const updateDriver = useCallback(async (driver: Driver) => {

    try {

      const updateDriver = { ...driver };

      // Set Ibutton active option
      if (updateDriver.hardware) updateDriver.hardware = activeOptionIbutton;

      // In case of optional field with no value
      if (!updateDriver.hardware) updateDriver.hardware = { ...{}, id_hardware: "" } as Hardware;
      if (!updateDriver.external_id) updateDriver.external_id = null;

      // Update a driver
      setLoadingDriverList(true);
      const { data } = await api.patch(`drivers/update/${driverDetails.id_driver}`, updateDriver);

      if (data.status === "success") {

        handleCloseModalForm(); // Close modal
        addToast({ type: "success", title: t(ToastMessages.success), description: data.message });

        setDriverDetails(Object.assign(driverDetails, updateDriver));

        // Update row in table with updated driver data
        if (table.row(tableClickedRow).node()) table.row(tableClickedRow).data(driverDetails);
        table.draw();

      } else addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

    } catch (error) {

      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

    } finally {
      setLoadingDriverList(false);
    }

  }, [addToast, table, tableClickedRow, driverDetails, handleCloseModalForm, activeOptionIbutton, t]);

  /** Delete selected driver
   * @param driver Driver to delete
   */
  const deleteDriver = useCallback(async (driver: Driver) => {

    try {

      setLoadingDriverList(true);
      const { data } = await api.delete(`drivers/delete/${driver.id_driver}`);

      if (data.status === "success") {

        setOpenDialogConfirmDelete(false); // Close dialog confirm delete
        addToast({ type: "success", title: t(ToastMessages.success), description: data.message });

        table.row(tableClickedRow).remove(); // Removes the deleted record from the table (WE NEED THE REFERENCE OF ROW)
        table.search("");
        table.draw();

      } else addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

    } catch (error) {

      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

    } finally {
      setLoadingDriverList(false);
    }

  },
  // eslint-disable-next-line
    [table, tableClickedRow]);

  /* endregion Functions */
  /* region Effects */
  // Get Ibutton hardware options
  useEffect(() => {
    if (!openOptionsIbuttons) setOptionsIbuttons([]);
  }, [openOptionsIbuttons]);
  useEffect(() => {

    let active = true;

    if (!loadingOptionsIbuttons) return undefined;

    (async () => {

      try {

        // Get all hardwares
        const { data } = await api.get("hardwares/read");

        if (data.status === "success") {
          // Filter to Ibutton only
          if (active) setOptionsIbuttons(data.result.filter((hardware: Hardware) => hardware.type.description === HardwareTypes.IBUTTON));

        } else {
          setOpenOptionsIbuttons(false);
          addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });
        }

      } catch (error: any) {

        setOpenOptionsIbuttons(false);

        if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
        else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

      }

    })();

    return () => { active = false; };

  }, [loadingOptionsIbuttons, addToast, t]);

  // Used to disable and change text when is searching for data
  useEffect(() => {

    const btnPesquisar = document.querySelector(".btn-pesquisar") as HTMLElement;

    if (btnPesquisar) {
      btnPesquisar.innerText = loadingDriverList ? "Buscando motoristas..." : "Pesquisar";

      if (loadingDriverList) {
        btnPesquisar.setAttribute("disabled", "");
      } else {
        btnPesquisar.removeAttribute("disabled");
      }
    }
  }, [loadingDriverList]);

  // Get all drivers on first render and when language changes
  useEffect(() => {
    setDrivers([]);

    readDrivers().then();
  }, [readDrivers, i18n.language]);

  /* endregion Effects */

  return (
    <>
      <Header title={t(MenuMessages.drivers)} />
      <Container platform={screen.platform as ScreenPlatform} className="page">
        <Loading loading={loadingDriverList} />
        {
          !loadingDriverList && (
            <NewDataTable
              title={t(MenuMessages.drivers).toLowerCase()}
              filters
              data={drivers}
              columns={dataTableColumns}
              actions={dataTableActions}
              settings={dataTableSettings}
              buttons={dataTableButtons}
              returnTable={(table) => setTable(table)}
              returnClickedRow={(clickedRow) => setTableClickedRow(clickedRow)}
            />
          )
        }
        <DialogConfirmAction
          open={openDialogConfirmDelete}
          onClose={() => setOpenDialogConfirmDelete(false)}
          title={t(DriverModuleMessages.deleteConfirmation)}
          actions={[
            { text: t(GlobalMessages.no), action: () => setOpenDialogConfirmDelete(false) },
            { text: t(GlobalMessages.yes), action: () => deleteDriver(driverDetails) }
          ]}
        >
          {t(DriverModuleMessages.deleteConfirmationText)} <br /><br />
          { t(DriverModuleMessages.fullname) }: {driverDetails.name} <br />
          { t(DriverModuleMessages.deleteConfirmationHolder) } { t(DriverModuleMessages.document) }: {driverDetails.cpf}?
        </DialogConfirmAction>
        <ContainerModalFormDriver id="modalFormDriver">
          <Dialog
            open={openModalFormDriver}
            onClose={handleCloseModalForm}
            scroll="paper"
            container={document.getElementById("modalFormDriver")}
          >
            <DialogTitle className="mHeader">
              <div className="content">
                <div className="title">{defineValueAccordingModalType(driverDetails.name, driverDetails.name, t(DriverModuleMessages.detailsTitle))}</div>
                <div className="subtitle">{defineValueAccordingModalType(driverDetails.cpf, driverDetails.cpf, "")}</div>
              </div>
              <div className="actions">
                {modalType === "details"
                  && (
                    <Button disableRipple onClick={() => setModalType("update")}><EditIcon /></Button>
                  )}
              </div>
            </DialogTitle>
            <DialogContent dividers className="mContent">
              <Form className="form" ref={formRef} onSubmit={(formData) => validations.validateForm(formData, modalType)}>
                <DialogContentText tabIndex={-1} component="div">
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={12} lg={12} className="checkboxes">
                      <FormControlLabel
                        className="r-checkbox"
                        control={(
                          <Checkbox
                            onChange={(event) => setActiveCheckbox(event.target.checked)}
                            checked={activeCheckbox}
                            name="active"
                            disabled={modalType === "details"}
                          />
                        )}
                        label={t(DriverModuleMessages.detailsActive)}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <InputMask
                        mask="999.999.999-99"
                        maskChar=""
                        defaultValue={defineValueAccordingModalType(driverDetails.cpf, driverDetails.cpf, "")}
                        onChange={() => validations.validateCpf(formRef.current?.getFieldValue("cpf"))}
                        disabled={modalType === "details"}
                      >
                        {() => (
                          <TextField
                            className="cpf"
                            label={t(DriverModuleMessages.document)}
                            margin="dense"
                            variant="outlined"
                            name="cpf"
                            InputLabelProps={{ shrink: formRef.current?.getFieldValue("cpf").length > 0 ? true : undefined }}
                            InputProps={{
                              endAdornment: (
                                <>
                                  {loadingValidateFieldCpf ? <CircularProgress color="inherit" size={20} /> : null}
                                </>
                              )
                            }}
                            disabled={modalType === "details"}
                            helperText={t(GlobalMessages.required)}
                          />
                        )}
                      </InputMask>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        className="re"
                        label={t(DriverModuleMessages.re)}
                        margin="dense"
                        variant="outlined"
                        name="re"
                        InputLabelProps={{ shrink: formRef.current?.getFieldValue("re").length > 0 ? true : undefined }}
                        InputProps={{
                          endAdornment: (
                            <>
                              {loadingValidateFieldRe ? <CircularProgress color="inherit" size={20} /> : null}
                            </>
                          )
                        }}
                        disabled={modalType === "details"}
                        defaultValue={defineValueAccordingModalType(driverDetails.re, driverDetails.re, "")}
                        onBlur={() => validations.validateRe(formRef.current?.getFieldValue("re"))}
                        onChange={() => validations.validateFieldError("re")}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <TextField
                        className="name"
                        label={t(DriverModuleMessages.fullname)}
                        margin="dense"
                        variant="outlined"
                        name="name"
                        InputLabelProps={{ shrink: formRef.current?.getFieldValue("name").length > 0 ? true : undefined }}
                        disabled={modalType === "details"}
                        helperText={t(GlobalMessages.required)}
                        defaultValue={defineValueAccordingModalType(driverDetails.name, driverDetails.name, "")}
                        onChange={() => validations.validateFieldError("name")}
                      />
                    </Grid>
                    {user.admin && (
                      <Grid item xs={12} md={12} lg={12}>
                        <TextField
                          label={t(LocationsModuleMessages.externalId)}
                          margin="dense"
                          variant="outlined"
                          name="external_id"
                          InputLabelProps={{ shrink: formRef.current?.getFieldValue("external_id").length > 0 ? true : undefined }}
                          disabled={modalType === "details" || !user.super_admin}
                          defaultValue={defineValueAccordingModalType(driverDetails.external_id, driverDetails.external_id, "")}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} md={7} lg={7}>
                      <TextField
                        className="licenseNumber"
                        label={t(DriverModuleMessages.detailsDocument)}
                        margin="dense"
                        variant="outlined"
                        name="license_number"
                        InputLabelProps={{ shrink: formRef.current?.getFieldValue("license_number").length > 0 ? true : undefined }}
                        disabled={modalType === "details"}
                        defaultValue={defineValueAccordingModalType(driverDetails.license_number, driverDetails.license_number, "")}
                      />
                    </Grid>
                    <Grid item xs={12} md={5} lg={5}>
                      <TextField
                        className="licenseExpiredDate"
                        label={t(DriverModuleMessages.licenseExpiredDate)}
                        name="license_expired_date"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        disabled={modalType === "details"}
                        defaultValue={
                          !_.isEmpty(driverDetails) && driverDetails.license_expired_date
                          && defineValueAccordingModalType(
                            dateFns.format(new Date(driverDetails.license_expired_date), "yyyy-MM-dd"),
                            dateFns.format(new Date(driverDetails.license_expired_date), "yyyy-MM-dd"),
                            ""
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <Autocomplete
                        open={openOptionsIbuttons}
                        onOpen={() => setOpenOptionsIbuttons(true)}
                        onClose={() => setOpenOptionsIbuttons(false)}
                        getOptionSelected={(option, value) => option.dev_id === value.dev_id}
                        getOptionLabel={(option) => `${option.dev_id
                          ? `${option.dev_id}` : ""}${option?.driver?.name
                            ? ` - ${option.driver.name}` : ""}`}
                        getOptionDisabled={(option) => !_.isEmpty(option.driver)}
                        options={optionsIbuttons.sort((a, b) => {
                          if (a.driver && b.driver) {
                            return a.driver.name.localeCompare(b.driver.name);
                          }
                          if (a.driver && !b.driver) {
                            return 1;
                          }
                          if (!a.driver && b.driver) {
                            return -1;
                          }

                          return 0;
                        })}
                        disabled={modalType === "details"}
                        loading={loadingOptionsIbuttons}
                        groupBy={(option) => (option.driver ? t(DriverModuleMessages.detailsUsedGroup) : t(DriverModuleMessages.detailsUsedGroup))}
                        onChange={(event, value) => setActiveOptionIbutton(value)}
                        defaultValue={
                          !_.isEmpty(driverDetails?.hardware)
                          && defineValueAccordingModalType(driverDetails?.hardware, driverDetails?.hardware, "")
                        }
                        value={activeOptionIbutton}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="hardware"
                            label={t(DriverModuleMessages.detailsIButtonCode)}
                            margin="dense"
                            variant="outlined"
                            InputLabelProps={{
                              shrink: formRef.current?.getFieldValue("hardware").length > 0 ? true : undefined
                            }}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {loadingOptionsIbuttons ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                </>
                              )
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </DialogContentText>
              </Form>
            </DialogContent>
            <DialogActions className="mFooter">
              <div className="actions">
                {
                    modalType === "details"
                      ? <></>
                      : modalType === "register"
                        ? <Button disableRipple type="submit" color="primary" onClick={() => formRef.current?.submitForm()}>{t(GlobalMessages.register)}</Button>
                        : <Button disableRipple type="submit" color="primary" onClick={() => formRef.current?.submitForm()}>{t(GlobalMessages.save)}</Button>
                  }
              </div>
              <Button disableRipple onClick={() => handleCloseModalForm()} color="primary">{t(GlobalMessages.close)}</Button>
            </DialogActions>
          </Dialog>
        </ContainerModalFormDriver>
      </Container>
    </>
  );
};

export default Drivers;
