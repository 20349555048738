export enum VehicleAndTravelDetailsMessages {
  noActiveTravel = "vehicleAndTravelDetails.noActiveTravel",
  noActiveTravelDescription = "vehicleAndTravelDetails.needActiveTravel",
  noDescriptionRegistered = "vehicleAndTravelDetails.noDescriptionRegistered",
  construction = "vehicleAndTravelDetails.construction",
  alertsTitle = "vehicleAndTravelDetails.alertsTitle",
  noAlertsOnTravel = "vehicleAndTravelDetails.noAlertsOnTravel",
  currentLoad = "vehicleAndTravelDetails.currentLoad",
  waterAdded = "vehicleAndTravelDetails.waterAdded",
  litersAdded = "vehicleAndTravelDetails.addedLiters",
  noWaterAdded = "vehicleAndTravelDetails.noWaterAdded",
  travelStatus = "vehicleAndTravelDetails.travelStatus",
  mapPosition = "vehicleAndTravelDetails.mapPosition",
  allocation = "vehicleAndTravelDetails.allocation",
  lockVehicle = "vehicleAndTravelDetails.lockVehicle",
  unlockVehicle = "vehicleAndTravelDetails.unlockVehicle",
  readWaterAdditionsSuccess = "vehicleAndTravelDetails.readWaterAdditionsSuccess",
  noWaterAdditionsFound = "vehicleAndTravelDetails.noWaterAdditionsFound",
  readWaterAdditionsError = "vehicleAndTravelDetails.readWaterAdditionsError"
}

export type TranslationVehicleAndTravelDetailsMessages = {
  [key in VehicleAndTravelDetailsMessages]: string;
}
