import { ManagementMessages, TranslationManagementMessages } from "../interfaces";

export const messages: TranslationManagementMessages = {
  [ManagementMessages.allowedChangeParams]: "Access to settings",
  [ManagementMessages.allowedChangeVehicles]: "Access to vehicles",
  [ManagementMessages.allowedChangeDrivers]: "Access to drivers",
  [ManagementMessages.allowedChangeLocations]: "Access to locations",
  [ManagementMessages.functionalityGroup]: "Functionalities",
  [ManagementMessages.alertVisualizationGroup]: "Alert visualization",
  [ManagementMessages.dataTableName]: "Name",
  [ManagementMessages.dataTableRegisterDate]: "Register date",
  [ManagementMessages.dataTableLastAccess]: "Last access",
  [ManagementMessages.dataTableEmail]: "Email",
  [ManagementMessages.dataTableCode]: "Code",
  [ManagementMessages.detailsActiveUser]: "Active user",
  [ManagementMessages.detailsDailyReport]: "Receive daily report",
  [ManagementMessages.detailsWeeklyReport]: "Receive weekly report",
  [ManagementMessages.detailsFullname]: "Full name",
  [ManagementMessages.detailsEmail]: "Email",
  [ManagementMessages.detailsAdmin]: "Administrator",
  [ManagementMessages.detailsAuthorizedPlants]: "Authorized plants",
  [ManagementMessages.detailsPassword]: "Password",
  [ManagementMessages.detailsConfirmPassword]: "Confirm password",
  [ManagementMessages.detailsTelegramId]: "Telegram ID",
  [ManagementMessages.detailsPermissions]: "Permissions",
  [ManagementMessages.detailsResendEmail]: "Resend email",
  [ManagementMessages.detailsTelegramIdInfo]: "Receive alerts via Telegram. To learn more, click here.",
  [ManagementMessages.detailsTelegramError]: "Unable to send latest alerts. Check the instructions HERE.",
  [ManagementMessages.detailsHeaderRegister]: "User registration",
  [ManagementMessages.deleteConfirmationText]: "Are you sure you want to delete this user",
  [ManagementMessages.deleteConfirmationTitle]: "Delete user confirmation",
  [ManagementMessages.validationTitle]: "User validation",
  [ManagementMessages.validationText]: "An email confirming your account and registering a new password will be sent to you",
  [ManagementMessages.validationConfirmation]: "Do you want to proceed?",
  [ManagementMessages.formEmailRegistered]: "Email already registered",
  [ManagementMessages.formRequiredName]: "Name is required",
  [ManagementMessages.formRequiredEmail]: "Email is required",
  [ManagementMessages.formValidEmail]: "Provide a valid email",
  [ManagementMessages.formRequiredPlant]: "Select at least one authorized plant",
  [ManagementMessages.formValidPassword]: "The password must contain at least 6 characters",
  [ManagementMessages.formValidConfirmPassword]: "The password must contain at least 6 characters",
  [ManagementMessages.formEqualsPassword]: "Passwords don't match"
};
