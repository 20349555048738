/* eslint-disable import/prefer-default-export */
import { TranslationHelpMessages, HelpMessages } from "../interfaces";

export const messages: TranslationHelpMessages = {
  [HelpMessages.titleColors]: "Colores",
  [HelpMessages.titleIcons]: "Iconos",

  [HelpMessages.subtitleOperationalStates]: "Estados Operativos",
  [HelpMessages.subtitleSituationalStates]: "Estados Situacionales",
  [HelpMessages.subtitleAlerts]: "Alertas",

  [HelpMessages.descriptionOperationalStatesGoal]: "Refleja la condición actual del vehículo en el contexto de producción.",
  [HelpMessages.descriptionOperationalStatesUtilization]: "Estos colores se utilizan en las columnas de los tableros y"
  + " iconos de vehículos en los mapas.",
  [HelpMessages.descriptionSituationalStatesGoal]: "Indica la situación actual del vehículo.",
  [HelpMessages.descriptionSituationalStatesUtilization]: "Estos colores se presentan en el color de fondo del icono de"
  + " vehículos dentro de las tarjetas.",

  [HelpMessages.blockedOrImproperDischarge]: "Bloqueado o tiene descargas inapropiadas en el viaje actual",
  [HelpMessages.active]: "Vehículo activo",
  [HelpMessages.hasAlerts]: "Contiene alertas en el viaje actual",
  [HelpMessages.noCommunication]: "Vehículo sin conexión con la plataforma"

};
