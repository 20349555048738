import { addDays, subDays, addHours, subHours, isValid, differenceInHours, differenceInDays } from "date-fns";

export type TimeUnit = "HOURS" | "DAYS";

export default class DateRange {

  constructor(start?: Date | string, end?: Date | string) {
    if (start) this.start_date = start instanceof Date ? start : new Date(start);
    if (end) this.end_date = end instanceof Date ? end : new Date(end);
  }

  start_date?: Date;

  end_date?: Date;

  /**
   * Update start_date or end_date if the other is empty to the default range
   * If both are empty, set end_date to now and start_date to the default range
   * @param timeUnit Time unit to calculate the range
   * @param defaultRange Default range to set
   */
  setDefaultRangeIfEmptyDates(timeUnit: TimeUnit, defaultRange: number): void {

    if (this.start_date && this.end_date) return;

    if (this.start_date) {
      this.end_date = timeUnit === "DAYS" ? addDays(this.start_date, defaultRange) : addHours(this.start_date, defaultRange);

    } else if (this.end_date) {
      this.start_date = timeUnit === "DAYS" ? subDays(this.end_date, defaultRange) : subHours(this.end_date, defaultRange);

    } else {
      this.end_date = new Date();
      this.start_date = timeUnit === "DAYS" ? subDays(this.end_date, defaultRange) : subHours(this.end_date, defaultRange);
    }

  }

  /**
   * Validate range of dates
   * @param timeUnit Time unit to calculate the range
   * @param maxRange Max range time according to the time unit
   */
  isValidRange(timeUnit: TimeUnit, maxRange: number): boolean {

    if (!isValid(this.start_date) || !isValid(this.end_date)) return false;

    const range = timeUnit === "DAYS"
      ? differenceInDays(this.end_date, this.start_date) : differenceInHours(this.end_date, this.start_date);

    return (range <= maxRange && this.end_date >= this.start_date);
  }

}
