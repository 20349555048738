/* eslint-disable import/prefer-default-export */
import { TranslationVehicleAndTravelDetailsMessages, VehicleAndTravelDetailsMessages } from "../interfaces";

export const messages: TranslationVehicleAndTravelDetailsMessages = {
  [VehicleAndTravelDetailsMessages.noActiveTravel]: "No active travel in the vehicle!",
  [VehicleAndTravelDetailsMessages.noActiveTravelDescription]: "Need active travel for coordinate search",
  [VehicleAndTravelDetailsMessages.noDescriptionRegistered]: "No description registered",
  [VehicleAndTravelDetailsMessages.construction]: "Construction",
  [VehicleAndTravelDetailsMessages.alertsTitle]: "Alerts",
  [VehicleAndTravelDetailsMessages.noAlertsOnTravel]: "No alerts related to this travel",
  [VehicleAndTravelDetailsMessages.currentLoad]: "Current load",
  [VehicleAndTravelDetailsMessages.waterAdded]: "Water added",
  [VehicleAndTravelDetailsMessages.litersAdded]: "Liters added",
  [VehicleAndTravelDetailsMessages.noWaterAdded]: "No water added",
  [VehicleAndTravelDetailsMessages.travelStatus]: "Travel status",
  [VehicleAndTravelDetailsMessages.mapPosition]: "Map position",
  [VehicleAndTravelDetailsMessages.allocation]: "Allocation",
  [VehicleAndTravelDetailsMessages.lockVehicle]: "Lock vehicle",
  [VehicleAndTravelDetailsMessages.unlockVehicle]: "Unlock vehicle",
  [VehicleAndTravelDetailsMessages.readWaterAdditionsSuccess]: "Water additions returned successfully",
  [VehicleAndTravelDetailsMessages.noWaterAdditionsFound]: "No water additions found",
  [VehicleAndTravelDetailsMessages.readWaterAdditionsError]: "Failed to search for water additions"
};
