/* eslint-disable import/prefer-default-export */
import { LoginMessages, TranslationLoginMessages } from "../interfaces";

export const messages: TranslationLoginMessages = {

  /* region Frontend messages */
  [LoginMessages.goToLoginPage]: "Go to login page",
  [LoginMessages.expiredSession]: "Your session has expired",
  [LoginMessages.expiredSessionInstructions]: "Log in again with your access user to continue using the system safely",
  [LoginMessages.login]: "Login",
  [LoginMessages.forgotMyPassword]: "Forgot my password",
  [LoginMessages.recoverPassword]: "Recover password",
  [LoginMessages.redirecting]: "Redirecting to access...",
  [LoginMessages.sending]: "Sending...",
  [LoginMessages.passwordConfirmation]: "Password confirmation",
  [LoginMessages.resettingPassword]: "Resetting...",
  [LoginMessages.resetPassword]: "Reset",
  [LoginMessages.resetLinkExpired]: "Reset link expired",
  [LoginMessages.resetLinkExpiredInstructions]: "The password reset link has expired!",
  [LoginMessages.requestAnotherLink]: "Request another reset email and try again.",
  [LoginMessages.password]: "Password",
  /* endregion Frontend messages */
  /* region Backend messages */
  [LoginMessages.accessAllowed]: "Access allowed",
  [LoginMessages.tokenActivationSuccess]: "Token activated successfully",
  [LoginMessages.tokenDeactivationSuccess]: "Token deactivated successfully",
  [LoginMessages.tokenDeleteSuccess]: "Token deleted successfully",
  [LoginMessages.instructionsEmailSent]: "Email with instructions sent successfully!",
  [LoginMessages.wrongPassword]: "Wrong password",
  [LoginMessages.onlyAdminCanGenerateIntegrationToken]: "Only admin users can generate an integration token",
  [LoginMessages.tokenNotRegistered]: "Token not registered in our system",
  [LoginMessages.tokenNotLinkedToUserSent]: "The token does not belong to the user sent",
  [LoginMessages.wrongIDFormat]: "ID(s) is(are) not in the correct format"
  /* endregion Backend messages */
};
