import { TravelHistoryMessages, TranslationTravelHistoryMessages } from "../interfaces";

export const messages: TranslationTravelHistoryMessages = {
  [TravelHistoryMessages.time]: "time",
  [TravelHistoryMessages.distance]: "Distance",
  [TravelHistoryMessages.durationInMinutes]: "Duration (in minutes)",
  [TravelHistoryMessages.distanceInKilometers]: "Distance (in kilometers)",
  [TravelHistoryMessages.document]: "Doc.",
  [TravelHistoryMessages.origin]: "Origin",
  [TravelHistoryMessages.destination]: "Destination",
  [TravelHistoryMessages.finishPoint]: "Finish point",
  [TravelHistoryMessages.travelHistoryTitle]: "Travel history",
  [TravelHistoryMessages.travels]: "Travels",
  [TravelHistoryMessages.coordinatesTitle]: "Coordinates",
  [TravelHistoryMessages.on]: "On",
  [TravelHistoryMessages.off]: "Off",
  [TravelHistoryMessages.occurrenceDate]: "Occurrence date",
  [TravelHistoryMessages.status]: "Status",
  [TravelHistoryMessages.ignition]: "Ignition",
  [TravelHistoryMessages.speed]: "Speed",
  [TravelHistoryMessages.latitude]: "Latitude",
  [TravelHistoryMessages.longitude]: "Longitude",
  [TravelHistoryMessages.average]: "Average",
  [TravelHistoryMessages.movingAverage]: "Moving average",
  [TravelHistoryMessages.records]: "records",
  [TravelHistoryMessages.averageSpeed]: "Average speed",
  [TravelHistoryMessages.registrationDate]: "Registration date",
  [TravelHistoryMessages.intervalBetweenLastCommand]: "Interval",
  [TravelHistoryMessages.analyticReport]: "Analytic report",
  [TravelHistoryMessages.syntheticReport]: "Synthetic report",
  [TravelHistoryMessages.vehiclePlate]: "Plate",
  [TravelHistoryMessages.vehicleType]: "Vehicle type",
  [TravelHistoryMessages.plant]: "Plant",
  [TravelHistoryMessages.regional]: "Regional",
  [TravelHistoryMessages.group]: "Group"
};
