import React, { useCallback, useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useToast } from "@hooks/useToast";
import useTranslation from "src/translations/useTranslation";
import { useAuth } from "@hooks/useAuth";
import NewDataTable, { DataTableActions, DataTableButtons, DataTableColumns } from "@molecules/NewDataTable";
import {
  DataTableMessages,
  GlobalMessages,
  LocationsModuleMessages, ManagementMessages, MenuMessages,
  ToastMessages
} from "@shared/languages/interfaces";
import ReactDOMServer from "react-dom/server";
import { RegionalsModuleMessages } from "@shared/languages/interfaces/regionalsModuleMessages";
import utils from "@utils/useful-functions";
import api from "@services/api";
import Header from "@components/Header";
import { ScreenPlatform } from "@store/ducks/Screen/screen.type";
import Loading from "react-fullscreen-loading";
import DialogConfirmAction from "@components/Dialog/ConfirmAction";
import {
  Button, CircularProgress,
  Dialog, DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid
} from "@material-ui/core";
import ButtonTable from "@components/Button/ButtonTable";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import { Form } from "@unform/web";
import { Checkbox, TextField } from "unform-material-ui";
import { FormHandles } from "@unform/core";
import * as dateFns from "date-fns";
import Autocomplete from "@material-ui/lab/Autocomplete";
import * as Yup from "yup";
import getValidationErrors from "@hooks/getValidationErrors";
import { Group } from "@shared/interfaces/group.interface";
import { Regional } from "@shared/interfaces/regional.interface";

import { Container, ContainerModalFormRegional } from "./styles";

const Regionals: React.FC = () => {

  /* endregion Refs */
  /* region States */
  const [regionals, setRegionals] = useState<Regional[]>([] as Array<Regional>);
  const [openOptionsGroups, setOpenOptionsGroups] = useState(false);
  const [optionGroups, setOptionGroups] = useState<Group[]>([] as Array<Group>);
  const [currentGroup, setCurrentGroup] = useState<Group>({} as Group);

  const [openDialogConfirmDelete, setOpenDialogConfirmDelete] = useState(false);
  const [openModalFormRegional, setOpenModalFormRegional] = useState(false);
  const [loadingRegionalsList, setLoadingRegionalList] = useState(false);
  const [regionalDetails, setRegionalDetails] = useState<Regional>({} as Regional);
  const [modalType, setModalType] = useState<"details" | "update" | "register">("details");
  const [activeCheckbox, setActiveCheckbox] = useState<boolean>(true);
  const [table, setTable] = useState<DataTables.Api>({} as DataTables.Api);
  const [loadingValidateFieldCode, setLoadingValidateFieldCode] = useState(false);
  const [tableClickedRow, setTableClickedRow] = useState<JQuery<HTMLTableRowElement>>({} as JQuery<HTMLTableRowElement>);
  /* endregion States */
  /* region Hooks */
  const { screen } = useSelector((screen) => screen);
  const { addToast } = useToast();
  const { t, i18n } = useTranslation();
  const { user } = useAuth();
  /* endregion Hooks */
  const loadingOptionsGroups = openOptionsGroups && optionGroups.length === 0;
  /* region Form Validation */
  const formRef = useRef<FormHandles>(null);
  /** Validations of unique fields
   */
  const validations = {

    validateFieldError: (fieldName: string) => {
      if (formRef.current?.getFieldError(fieldName)?.length) formRef.current?.setFieldError(fieldName, "");
    },
    validateCode: async (code: string) => {

      try {
        setLoadingValidateFieldCode(true);
        const foundRegional = regionals.find((regional) => regional.id_regional === code);

        if (foundRegional) {
          const error = new Error("Regionals Code already exists");

          formRef.current?.setErrors(getValidationErrors(error));
        }
      } finally {
        setLoadingValidateFieldCode(false);
      }

    },
    validateForm: async (formData: Regional, action: "details" | "register" | "update") => {
      try {
        // Define the validation types
        const schema = Yup.object().shape({
          name: Yup.string().trim().required(t(ManagementMessages.formRequiredName)),
          code: Yup.string().trim().required()
        });

        // Validate inputs
        await schema.validate(formData, { abortEarly: false });
        // Register or update regional (According action selected)
        if (action === "register") await createRegional(formData);
        else await updateRegional(formData);

      } catch (error) {
        formRef.current?.setErrors(getValidationErrors(error));
      }
    }
  };
  /* endregion Form Validation */

  useEffect(() => {
    if (!openOptionsGroups) setOptionGroups([]);
  }, [openOptionsGroups]);
  useEffect(() => {
    let active = true;

    if (!loadingOptionsGroups) return undefined;

    (async () => {
      try {
        const { data } = await api.get("groups/read");

        if (data.status === "success") {
          if (active) {
            const result = data.result.filter((group) => group.active);

            setOptionGroups(result);
          }
        } else {
          setOpenOptionsGroups(false);
          addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });
        }
      } catch (error) {
        setOpenOptionsGroups(false);

        if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
        else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });
      }
    })();

    return () => { active = false; };

  }, [loadingOptionsGroups, addToast, t]);
  /* region Constants */
  const dataTableSettings: DataTables.Settings = {
    order: [[0, "asc"]],
    columnDefs: [{ className: "dt-center", targets: -1 }]
  };
  const dataTableActions: DataTableActions[] = [
    {
      ref: ".modules-regional-list-details",
      callback: (rowData: Regional) => handleRegionalDetails(rowData)
    },
    {
      ref: ".modules-regional-list-delete",
      callback: (rowData: Regional) => handleRegionalDelete(rowData)
    }
  ];
  const dataTableColumns: DataTableColumns[] = [
    { // Nome
      title: t(RegionalsModuleMessages.name),
      data: (regional: Regional) => regional.name || t(RegionalsModuleMessages.noNameRegistered),
      filterable: true,
      defaultContent: "",
      propertyName: "name"
    },
    { // Id Externo
      title: t(RegionalsModuleMessages.externalId),
      data: (regional: Regional) => regional.external_id,
      filterable: true,
      defaultContent: "",
      propertyName: "number"
    },
    { // Data de Registro
      title: t(RegionalsModuleMessages.registrationDate),
      data: (regional: Regional) => regional.registration_date,
      filterable: true,
      defaultContent: "",
      propertyName: "registrationDate",
      // eslint-disable-next-line max-len
      render: (data) => (data ? (dateFns.format(new Date(data), "dd/MM/yyyy HH:mm")) : "")
    },
    { // Data Atualização
      title: t(RegionalsModuleMessages.updatedDate),
      data: (regional: Regional) => regional.updated_date,
      filterable: true,
      defaultContent: "",
      propertyName: "updatedDate",
      render: (data) => (data ? (dateFns.format(new Date(data), "dd/MM/yyyy HH:mm")) : "")
    },
    { // Ativo
      title: "Status",
      filterable: true,
      propertyName: "active",
      data: (regional: Regional) => (
        ReactDOMServer.renderToString(
          <b style={{ color: regional.active ? "rgb(70, 193, 125)" : "rgb(230, 74, 25)" }}>
            {regional.active ? t(RegionalsModuleMessages.active).toUpperCase() : t(RegionalsModuleMessages.inactive).toUpperCase()}
          </b>
        )
      )
    },
    { // Ações
      title: t(DataTableMessages.actions),
      orderable: false,
      searchable: false,
      data: () => ReactDOMServer.renderToString(
        <Grid container spacing={1}>
          <Grid item xs sm md lg xl>
            <ButtonTable className="action-button modules-regional-list-details"><FormatListBulletedIcon /></ButtonTable>
          </Grid>
          <Grid item xs sm md lg xl>
            <ButtonTable className="action-button modules-regional-list-delete"><DeleteForeverIcon /></ButtonTable>
          </Grid>
        </Grid>
      ),
      width: "130px",
      filterable: false
    }
  ];
  const dataTableButtons: DataTableButtons[] = [
    {
      name: t(DataTableMessages.buttonsAddNew),
      key: "add",
      callback: () => FhandleRegionalCreate()
    },
    {
      name: t(DataTableMessages.buttonsRefresh),
      key: "refresh",
      callback: () => readRegionals()
    },
    {
      name: t(DataTableMessages.buttonsPrint),
      key: "print",
      callback: () => utils.clickButtonDomElement("button-print"),
      extend: "print",
      className: "button-print",
      exportOptions: {
        columns: "th:not(:last-child)"
      }
    },
    {
      name: t(DataTableMessages.buttonsExport),
      callback: () => utils.clickButtonDomElement("button-export"),
      extend: "csv",
      key: "export",
      fieldSeparator: ";",
      className: "button-export",
      filename: `relatorio_locais_${new Date().toISOString().split("T")[0]}`,
      exportOptions: {
        columns: "th:not(:last-child)"
      }
    }
  ];
  /* endregion Constants */
  // Actions on close/open modal/dialog

  // Actions close dialogs and modals
  const handleCloseModalForm = useCallback(() => {

    setRegionalDetails({} as Regional);

    setOpenModalFormRegional(false);

  }, []);
  /* region Functions */
  // Actions regionals list
  const handleRegionalDetails = useCallback((regional: Regional) => {

    setRegionalDetails(regional);
    setCurrentGroup(regional.group);
    setActiveCheckbox(regional.active ?? false);

    setModalType("details");

    setOpenModalFormRegional(true);

  }, [setRegionalDetails, setActiveCheckbox, setModalType, setOpenModalFormRegional]);
  const handleRegionalDelete = useCallback((regional: Regional) => {

    setRegionalDetails(regional);

    setOpenDialogConfirmDelete(true);

  }, [setRegionalDetails, setOpenDialogConfirmDelete]);
  /*
   * Open modal to create new Regional
   * */
  const FhandleRegionalCreate = () => {
    setModalType("register");
    setOpenModalFormRegional(true);
  };

  const createRegional = useCallback(async (regional: Regional) => {

    try {
      let insertRegional = { ...regional };

      if (currentGroup?.id_group) insertRegional.group = currentGroup;
      // Remove properties without value
      Object.keys(insertRegional).forEach((key) => {
        if (insertRegional[key].length <= 0) delete insertRegional[key];
      });

      if (insertRegional.active) insertRegional.active = activeCheckbox;
      setLoadingRegionalList(true);
      const { data } = await api.post("regionals/create", insertRegional);

      if (data.status === "success") {

        handleCloseModalForm(); // Close modal
        addToast({ type: "success", title: t(ToastMessages.success), description: data.message });

        insertRegional = data.result;

        if (!regionals.length) readRegionals();
        else {

          // Add inserted regional in the table
          table.row.add(insertRegional);
          table.draw();
        }

        // Search the inserted user and list
        table.search(regional.name).draw();

      } else addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

    } catch (error) {

      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

    } finally {
      setLoadingRegionalList(false);
    }
  }, [activeCheckbox, currentGroup, table, setLoadingRegionalList]);

  /** Update a regional
   * @param regional Regional to update
   */
  const updateRegional = useCallback(async (regional: Regional) => {

    try {

      const updateRegional = { ...regional };

      if (currentGroup?.id_group) updateRegional.group = currentGroup;
      else delete updateRegional.group;

      if (updateRegional.external_id === "") delete updateRegional.external_id;

      setLoadingRegionalList(true);
      const { data } = await api.patch(`regionals/update/${regionalDetails.id_regional}`, updateRegional);

      if (data.status === "success") {

        handleCloseModalForm(); // Close modal
        addToast({ type: "success", title: t(ToastMessages.success), description: data.message });

        setRegionalDetails(Object.assign(regionalDetails, updateRegional));

        // Update row in table with updated driver data
        table.row(tableClickedRow).data(regionalDetails);
        table.draw();

      } else addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

    } catch (error) {

      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

    } finally {
      setLoadingRegionalList(false);
    }

  }, [addToast, table, tableClickedRow, regionalDetails, handleCloseModalForm, t, currentGroup, setLoadingRegionalList, setRegionalDetails]);

  const handleCloseDialogConfirmDelete = useCallback(() => {
    setOpenDialogConfirmDelete(false);
  }, []);
  /** Check what modal type to show corresponding values and actions
   * @param detailValue
   * @param updateValue
   * @param registerValue
   */
  const defineValueAccordingModalType = useCallback((detailValue, updateValue, registerValue) => {

    switch (modalType) {
      case "details": return detailValue;
      case "update": return updateValue;
      case "register": return registerValue;
      default: return "S/N";
    }
  }, [modalType]);

  /** Get all Regionals data
   */
  const readRegionals = useCallback(async () => {

    try {

      // Get the travels of selected vehicle
      setLoadingRegionalList(true);
      const { data } = await api.get("regionals/read");

      if (data.status === "success") setRegionals(data.result);
      else addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

    } catch (error) {

      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

    } finally {
      setLoadingRegionalList(false);
    }

  }, [addToast, t]);
  /** Delete selected Regional
   * @param regional Regional to delete
   */
  const deleteRegional = useCallback(async (regional) => {

    try {

      setLoadingRegionalList(true);
      const { data } = await api.delete(`regionals/delete/${regional.id_regional}`);

      if (data.status === "success") {

        handleCloseDialogConfirmDelete(); // Close dialog confirm delete
        addToast({ type: "success", title: t(ToastMessages.success), description: data.message });

        // Removes the deleted record from the table (WE NEED THE REFERENCE OF ROW)
        table.row(tableClickedRow).remove();
        table.search("");
        table.draw();

      } else addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

    } catch (error) {

      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

    } finally {
      setLoadingRegionalList(false);
    }

  }, [addToast, handleCloseDialogConfirmDelete, table, tableClickedRow, t]);

  /* endregion Functions */
  /* region Effects */
  // Used to disable and change text when is searching for data
  useEffect(() => {
    const btnPesquisar = document.querySelector(".btn-pesquisar") as HTMLElement;

    if (btnPesquisar) {
      btnPesquisar.innerText = loadingRegionalsList ? t(LocationsModuleMessages.searching) : t(GlobalMessages.search);

      if (loadingRegionalsList) {
        btnPesquisar.setAttribute("disabled", "");
      } else {
        btnPesquisar.removeAttribute("disabled");
      }
    }
  }, [loadingRegionalsList, t]);
  useEffect(() => {
    readRegionals().then();
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [i18n.language]);

  /* endregion Effects */
  return (
    <>
      <Header title={t(MenuMessages.regionals)} />
      <Container platform={screen.platform as ScreenPlatform} className="page">
        <Loading loading={loadingRegionalsList} />
        {
          !loadingRegionalsList && (
            <NewDataTable
              title={t(LocationsModuleMessages.dataTableTitle)}
              filters
              data={regionals}
              columns={dataTableColumns}
              actions={dataTableActions}
              settings={dataTableSettings}
              buttons={dataTableButtons}
              returnTable={(table) => setTable(table)}
              returnClickedRow={(clickedRow) => setTableClickedRow(clickedRow)}
            />
          )
        }
        <DialogConfirmAction
          open={openDialogConfirmDelete}
          onClose={handleCloseDialogConfirmDelete}
          title={t(ManagementMessages.deleteConfirmationTitle)}
          actions={[
            { text: t(GlobalMessages.no), action: () => handleCloseDialogConfirmDelete() },
            { text: t(GlobalMessages.yes), action: () => deleteRegional(regionalDetails) }
          ]}
        >
          {t(RegionalsModuleMessages.deleteConfirmationText)} <br /><br />
          {t(ManagementMessages.dataTableName)}: {regionalDetails.name} <br />
          {t(ManagementMessages.dataTableCode)}: {regionalDetails.code}?
        </DialogConfirmAction>
        <ContainerModalFormRegional id="modalFormRegional">
          <Dialog
            open={openModalFormRegional}
            onClose={handleCloseModalForm}
            scroll="paper"
            container={document.getElementById("modalFormRegional")}
          >
            <DialogTitle className="mHeader">
              <div className="content">
                <div className="title">{defineValueAccordingModalType(regionalDetails.name, regionalDetails.name,
                  t(MenuMessages.regionals))}
                </div>
              </div>
              <div className="actions">
                {modalType === "details"
                  && (
                    <Button disableRipple onClick={() => setModalType("update")}><EditIcon /></Button>
                  )}
              </div>
            </DialogTitle>
            <DialogContent dividers className="mContent">
              <Form className="form" ref={formRef} onSubmit={(formData) => validations.validateForm(formData, modalType)}>
                <DialogContentText tabIndex={-1} component="div">
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={12} lg={12}>
                      <TextField
                        className="name"
                        label={t(RegionalsModuleMessages.name)}
                        defaultValue={regionalDetails.name}
                        margin="dense"
                        variant="outlined"
                        name="name"
                        InputLabelProps={{ shrink: formRef.current?.getFieldValue("name").length > 0 ? true : undefined }}
                        disabled={modalType === "details"}
                        helperText={t(GlobalMessages.required)}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        className="cod test"
                        label={t(RegionalsModuleMessages.code)}
                        defaultValue={regionalDetails.code}
                        margin="dense"
                        variant="outlined"
                        name="code"
                        InputLabelProps={{ shrink: formRef.current?.getFieldValue("code").length > 0 ? true : undefined }}
                        disabled={modalType === "details"}
                        helperText={t(GlobalMessages.required)}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        className="r-id"
                        label={t(RegionalsModuleMessages.externalId)}
                        defaultValue={regionalDetails.external_id}
                        margin="dense"
                        variant="outlined"
                        name="external_id"
                        InputLabelProps={{ shrink: formRef.current?.getFieldValue("external_id").length > 0 ? true : undefined }}
                        disabled={modalType === "details" || !user.super_admin}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      {/* GRUPO DE CENTRAIS */}
                      <Autocomplete
                        open={openOptionsGroups}
                        onOpen={() => setOpenOptionsGroups(true)}
                        onClose={() => setOpenOptionsGroups(false)}
                        getOptionSelected={(option, value) => option === value}
                        getOptionLabel={(option) => option.name}
                        options={optionGroups.sort((a, b) => -b.name.localeCompare(a.name))}
                        loading={loadingOptionsGroups}
                        disabled={modalType === "details"}
                        onChange={(event, value) => {
                          if (value) setCurrentGroup(value);
                        }}
                        value={regionalDetails.group}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="group"
                            label={t(MenuMessages.groups)}
                            margin="dense"
                            variant="outlined"
                            InputLabelProps={{ shrink: regionalDetails.group ? true : undefined }}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {loadingOptionsGroups ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                </>
                              )
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12} className="checkboxes">
                    <FormControlLabel
                      className="r-checkbox"
                      control={(
                        <Checkbox
                          onChange={(event) => setActiveCheckbox(event.target.checked)}
                          checked={activeCheckbox}
                          name="active"
                          disabled={modalType === "details"}
                        />
                      )}
                      label={t(RegionalsModuleMessages.active)}
                    />
                  </Grid>
                </DialogContentText>
              </Form>
            </DialogContent>
            <DialogActions className="mFooter">
              <div className="actions">
                {
                  modalType === "details"
                    ? <></>
                    : modalType === "register"
                      // eslint-disable-next-line max-len
                      ? <Button disableRipple type="submit" color="primary" onClick={() => formRef.current?.submitForm()}>{t(GlobalMessages.register)}</Button>
                      // eslint-disable-next-line max-len
                      : <Button disableRipple type="submit" color="primary" onClick={() => formRef.current?.submitForm()}>{t(GlobalMessages.save)}</Button>
                }
              </div>
              <Button disableRipple onClick={() => handleCloseModalForm()} color="primary">{t(GlobalMessages.close)}</Button>
            </DialogActions>
          </Dialog>
        </ContainerModalFormRegional>
      </Container>
    </>
  );
};

export default Regionals;
