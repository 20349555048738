import React, { useCallback, useState } from "react";
import Loading from "react-fullscreen-loading";

// region Libraries
import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, Divider } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import * as _ from "lodash";
// endregion Libraries

// region Interfaces and types
import { AlertTypesID } from "@shared/constants/alert-types.enum";
import { Alert as AlertInterface } from "@shared/interfaces/alert.interface";
import { LocationsFormState } from "@store/ducks/Locations/LocationsForm/locations-form.type";
// endregion Interfaces and types

// region Assets
import LogoFleet from "@assets/FleetLogo/fleetLogo.svg";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
// endregion Assets

// region Hooks
import { useToast } from "@hooks/useToast";
// endregion Hooks

// region Services
import api from "@services/api";
// endregion Services

// region Languages
import useTranslation from "src/translations/useTranslation";
import { AlertMessages, AlertTypeMessages, GlobalMessages } from "@shared/languages/interfaces";
// endregion Languages

// region Utils
import { TLanguages } from "@shared/languages/languageData";
import utils from "../../../utils/useful-functions";
// endregion Utils

// region Components
import AlertDialogJustify from "../AlertDialogJustify";
import { MapFixedPointCoord } from "../../Map";
// endregion Components

// region Styles
import { Container } from "./styles";
// endregion Styles

type AlertItemProps = { alerts: AlertInterface[]; onlyView?: boolean; }
const Alert: React.FC<AlertItemProps> = (
  { alerts, onlyView = false }
) => {
  const { addToast } = useToast();
  const { t, i18n } = useTranslation();
  const [expanded, setExpanded] = useState<string | false>(false);

  const [selectedAlert, setSelectedAlert] = useState<AlertInterface>({} as AlertInterface);
  const [selectedAlertActionType, setSelectedAlertActionType] = useState<LocationsFormState["type"]>("details");

  const [loading, setLoading] = useState(false);

  /** Control event of collapse panels
   * @param panel Panel id to change
   */
  const handleChangeCollapsePanels = useCallback(
    (panel: string) => (event: React.ChangeEvent<unknown>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    }, []
  );

  // Get alert data to show in  justify Alert
  const handleAlertShow = useCallback(async (alert: AlertInterface) => {

    const FreadAlert = async () => await (await api.post(`alerts/read/${alert.id_alert}`)).data;
    let alertMessageContent = "";

    setLoading(true);

    try {

      const promiseAlert = await FreadAlert();

      if (promiseAlert.status === "success") {
        const alert = promiseAlert?.result?.[0] as AlertInterface;

        setSelectedAlertActionType(alert?.confirmation_date ? "details" : "update");
        setSelectedAlert(alert);

      } else alertMessageContent += promiseAlert.message;

      // If we have some alert, show message to user
      if (!_.isEmpty(alertMessageContent)) {
        addToast(
          { type: "info", title: t(GlobalMessages.alert), description: alertMessageContent }
        );
      }

    } catch (error: any) {

      if (!error.response) addToast({ type: "error", title: t(GlobalMessages.error), description: t(GlobalMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

    } finally { setLoading(false); }

  }, [addToast, t]);

  // Set selected Alert to initial state
  const handleAlertClose = useCallback(() => {
    setSelectedAlert({} as AlertInterface);
  }, []);

  return (
    <Container>
      <Loading loading={loading} />
      {!_.isEmpty(alerts) && alerts.map((alert) => (
        <div key={alert.id_alert} id={alert.id_alert}>
          <Accordion
            key={`accordion-${alert.id_alert}`}
            expanded={expanded === alert.id_alert}
            onChange={handleChangeCollapsePanels(alert.id_alert)}
          >
            <AccordionSummary
              className="boxHeaderItemsEvent"
              expandIcon={<ExpandMoreIcon />}
              aria-controls="bh-content"
            >
              <div className="content">
                <div className="boxHeaderItemsIcon">
                  {utils.getAlertIconAccordingType(alert?.type?.id_alert_type ?? "")}
                </div>
                <div className="boxHeaderItemsDescription">
                  <div className="heading typeAndDuration">
                    <div
                      className="type"
                      style={{ color: alert.type.id_alert_type === AlertTypesID.BLOQUEIO ? "#F00" : "#000",
                        width: alert.type.id_alert_type === AlertTypesID.EXCESSO_DE_VELOCIDADE
                          || alert.type.id_alert_type === AlertTypesID.EXCESSO_DE_VELOCIDADE_VIAGEM ? "100%" : "" }}
                    >
                      {alert.type.id_alert_type === AlertTypesID.CONCRETO_PROXIMO_AO_VENCIMENTO ? t(AlertMessages.expiresIn)
                        : alert.type.id_alert_type === AlertTypesID.CONCRETO_VENCIDO ? `${t(AlertMessages.expiredConcrete)}:`
                          : t(AlertTypeMessages[alert.type.id_alert_type])}
                    </div>
                    {alert.type.id_alert_type !== AlertTypesID.EXCESSO_DE_VELOCIDADE
                    && alert.type.id_alert_type !== AlertTypesID.EXCESSO_DE_VELOCIDADE_VIAGEM
                      && alert?.event?.start_date
                      && alert?.event?.finish_date
                      && alert.event.start_date !== alert.event.finish_date
                      && (
                        <div className="duration">
                          {alert.type.id_alert_type === AlertTypesID.CONCRETO_PROXIMO_AO_VENCIMENTO
                            ? utils.formatDateIfHave(utils.calcDataRange(new Date(), alert.event.finish_date),
                              "durationDescriptiveTime")
                            : alert.type.id_alert_type === AlertTypesID.CONCRETO_VENCIDO ? utils.formatDateIfHave(
                              utils.calcDataRange(alert.event.finish_date, new Date()),
                              "durationDescriptiveTime"
                            )
                              : utils.formatDateIfHave(utils.calcDataRange(alert.event.start_date, alert.event.finish_date),
                                "durationDescriptiveTime")}
                        </div>
                      )}
                  </div>
                  {alert?.event?.start_date
                    && alert.type.id_alert_type !== AlertTypesID.BLOQUEIO
                    && alert.type.id_alert_type !== AlertTypesID.CONCRETO_VENCIDO
                    && alert.type.id_alert_type !== AlertTypesID.CONCRETO_PROXIMO_AO_VENCIMENTO
                    && alert.type.id_alert_type !== AlertTypesID.EXCESSO_DE_VELOCIDADE_VIAGEM
                    && alert.type.id_alert_type !== AlertTypesID.EXCESSO_DE_VELOCIDADE
                    && (
                      <div className="heading startDate">
                        <div>{t(GlobalMessages.start)}:</div>
                        <div>{utils.formatDateIfHave(alert.event.start_date, "time")}</div>
                      </div>
                    )}
                  {alert?.event?.start_date
                    && (alert.type.id_alert_type === AlertTypesID.BLOQUEIO
                    || alert.type.id_alert_type === AlertTypesID.CONCRETO_PROXIMO_AO_VENCIMENTO
                    || alert.type.id_alert_type === AlertTypesID.CONCRETO_VENCIDO)
                    && (
                      <>
                        <div className="heading startDate">
                          <div>{t(GlobalMessages.date)}:</div>
                          <div>
                            {alert.type.id_alert_type === AlertTypesID.CONCRETO_PROXIMO_AO_VENCIMENTO
                              || alert.type.id_alert_type === AlertTypesID.CONCRETO_VENCIDO
                              ? utils.formatDateIfHave(alert.event.finish_date, "date")
                              : utils.formatDateIfHave(alert.event.start_date, "date")}
                          </div>
                        </div>
                        <div className="heading finishDate">
                          <div>{t(GlobalMessages.time)}:</div>
                          <div>
                            {alert.type.id_alert_type === AlertTypesID.CONCRETO_PROXIMO_AO_VENCIMENTO
                            || alert.type.id_alert_type === AlertTypesID.CONCRETO_VENCIDO
                              ? utils.formatDateIfHave(alert.event.finish_date, "time")
                              : utils.formatDateIfHave(alert.event.start_date, "time")}
                          </div>
                        </div>
                      </>
                    )}
                  {alert?.event?.finish_date
                    && alert.type.id_alert_type !== AlertTypesID.EXCESSO_DE_VELOCIDADE
                    && alert.type.id_alert_type !== AlertTypesID.EXCESSO_DE_VELOCIDADE_VIAGEM
                    && alert.type.id_alert_type !== AlertTypesID.CONCRETO_PROXIMO_AO_VENCIMENTO
                    && alert.type.id_alert_type !== AlertTypesID.CONCRETO_VENCIDO
                    && alert.event.start_date !== alert.event.finish_date
                    && (
                      <div className="heading finishDate">
                        <div>{t(GlobalMessages.end)}:</div>
                        <div>{utils.formatDateIfHave(alert.event.finish_date, "time")}</div>
                      </div>
                    )}
                  {(alert.type.id_alert_type === AlertTypesID.EXCESSO_DE_VELOCIDADE_VIAGEM
                    || alert.type.id_alert_type === AlertTypesID.EXCESSO_DE_VELOCIDADE)
                    && alert?.event?.speed
                    && (
                      <div>
                        <div className="heading speed">
                          <div>{t(GlobalMessages.fullSpeed)}:</div>
                          <div>
                            <span style={{ color: "red" }}>
                              {utils.formatNumberToLocaleString(
                                alert.travel?.alert_maximum_speed ?? alert.vehicle?.maximum_speed_allowed,
                                i18n.language as TLanguages,
                                0
                              )}
                            </span>
                            <PlayArrowIcon width="0.4rem" />
                            <span style={{ color: "red" }}>
                              {utils.formatNumberToLocaleString(
                                alert.event?.speed,
                                i18n.language as TLanguages,
                                0
                              )} km/h
                            </span>
                          </div>
                        </div>
                        <div className="heading speed">
                          <div>{t(GlobalMessages.exceeded)}<span style={{ color: "#7E73F8" }}>{t(GlobalMessages.onTheWay)}</span></div>
                          <div>{utils.formatDateIfHave(alert.event.finish_date, "timeWithoutSeconds")}</div>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails className="boxContentItemsEvent">
              {!onlyView
                && (
                  <div className="actions">
                    <Button
                      disableRipple
                      color="primary"
                      onClick={() => handleAlertShow(alert)}
                    >
                      {t(AlertMessages.details)}
                    </Button>
                  </div>
                )}
              <div className="maps">
                <div className="staticMap" id={`static-${alert.id_alert}`}>
                  <img
                    src={LogoFleet}
                    alt="Visualizar localização"
                    onClick={() => utils.showDrawerMap(
                      "show",
                      `#drawer-${alert.id_alert}`,
                      `#static-${alert.id_alert}`,
                      <MapFixedPointCoord
                        mapHeight={250}
                        latitude={Number(alert?.event?.latitude || "0")}
                        longitude={Number(alert?.event?.longitude || "0")}
                        alertMarker
                      />
                    )}
                    aria-hidden="true"
                  />
                </div>
                <div className="drawerMap hidden" id={`drawer-${alert.id_alert}`}><CircularProgress /></div>
              </div>
            </AccordionDetails>
          </Accordion>
          <br />
          <Divider />
        </div>
      ))}

      {!onlyView
        && !_.isEmpty(selectedAlert)
        && (
          <AlertDialogJustify
            selectedAlert={selectedAlert}
            actionType={selectedAlertActionType}
            closeCallback={handleAlertClose}
            setLoading={setLoading}
            canDetailTravel={false}
          />
        )}
    </Container>
  );
};

export default Alert;
