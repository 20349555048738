import { UsersModuleMessages, TranslationUsersModuleMessages } from "../interfaces";

export const messages: TranslationUsersModuleMessages = {
  [UsersModuleMessages.createUserSuccess]: "Usuário cadastrado com sucesso",
  [UsersModuleMessages.confirmationEmailSent]: "Email de confirmação enviado",
  [UsersModuleMessages.createUserError]: "Falha ao cadastrar novo usuário",
  [UsersModuleMessages.readUserSuccess]: "Usuário(s) retornado(s) com sucesso",
  [UsersModuleMessages.noUserFound]: "Nenhum usuário encontrado",
  [UsersModuleMessages.readUserError]: "Falha ao buscar usuário(s)",
  [UsersModuleMessages.updateUserSuccess]: "Usuário alterado com sucesso",
  [UsersModuleMessages.userNotFound]: "Usuário não cadastrado em nosso sistema",
  [UsersModuleMessages.updateUserError]: "Falha ao alterar usuário",
  [UsersModuleMessages.deleteUserSuccess]: "Usuário excluído com sucesso",
  [UsersModuleMessages.deleteUserError]: "Falha ao excluir usuário",
  [UsersModuleMessages.inactiveAccountCheckEmail]: "Conta ainda não ativada, cheque seu email para ativação",
  [UsersModuleMessages.inactiveUser]: "Usuário inativo",
  [UsersModuleMessages.userNotAdmin]: "Usuário não tem direitos de administrador"
};
