import React from "react";
import { ReactSVG } from "react-svg";

// region Imports - Assets
import IconOdometer from "@assets/icons/odometer.svg";
import IconConnection from "@assets/icons/connection.svg";
import IconCanTelemetry from "@assets/icons/can-telemetry.svg";
import IconGpsSignal from "@assets/icons/gps-signal.svg";
import IconIgnitionStatus from "@assets/icons/key.svg";
import IconSensorSignal from "@assets/icons/sensor-signal.svg";
import IconSpeedStatus from "@assets/icons/speed-limit-status.svg";
// endregion Imports - Assets
// region Imports - Material-UI
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import RemoveIcon from "@material-ui/icons/Remove";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
// endregion Imports - Material-UI
// region Imports - Languages
import useTranslation from "src/translations/useTranslation";
import { GlobalMessages, VehicleStatusMessages } from "@shared/languages/interfaces";
// endregion Imports - Languages
// region Imports - Shared
import { Status as StatusColor } from "@shared/constants/colors.enum";
import { InternalParams } from "@shared/constants/internal-params.enum";
import { VehicleTypes } from "@shared/constants/vehicle-types.enum";
import { HardwareTypesID } from "@shared/constants/hardware-types.enum";
import { Vehicle } from "@shared/interfaces/vehicle.interface";
import { formatHourMeter, formatOdometer } from "@shared/utils/useful-functions";
// endregion Imports - Shared
// region Imports - Utils
import utils from "@utils/useful-functions";
// endregion Imports - Utils
// region Imports - Hooks
import { useAuth } from "@hooks/useAuth";
// endregion Imports - Hooks
// region Imports - Styles
import { Container } from "./styles";
// endregion Imports - Styles

type VehicleStatusProps = { vehicle: Vehicle; historical?: boolean; };
const VehicleStatus = React.memo<VehicleStatusProps>((
  { vehicle, historical = false }
) => {

  // region Hooks
  const { user } = useAuth();
  const { t } = useTranslation();
  // endregion Hooks

  return (
    <Container>
      {vehicle && !historical && (
        <>
          <div className="block status">
            {vehicle.hardwares && (
              vehicle.hardwares.map((hardware) => (
                <div className="content" key={hardware.id_hardware}>
                  <div className="icon">
                    <ReactSVG
                      src={hardware.type.id_hardware_type === HardwareTypesID.RASTREADOR ? IconGpsSignal : IconSensorSignal}
                      style={{ fill: utils.getHardwareDataStyle(hardware, "color") }}
                    />
                  </div>
                  <div className="boxContent">
                    <div className="title">{utils.getHardwareDataStyle(hardware, "description")}</div>
                    <div
                      className="text"
                      style={{ color: utils.getHardwareDataStyle(hardware, "color") }}
                    >
                      {utils.getHardwareDataStyle(hardware, "status")}<br />
                      {user.super_admin && (<span className="devId">{hardware.dev_id}</span>)}
                    </div>
                  </div>
                </div>
              ))
            )}
            <div className="content currentSpeed">
              <div className="icon">
                <ReactSVG
                  src={IconSpeedStatus}
                  style={{ fill: !vehicle.current_speed
                    ? StatusColor.INACTIVE
                    : (vehicle.maximum_speed_allowed && (Number(vehicle.current_speed) * InternalParams.SPEED_MARGIN) > Number(vehicle.maximum_speed_allowed))
                      ? StatusColor.ERROR
                      : StatusColor.ACTIVE
                  }}
                />
              </div>
              <div className="boxContent">
                <div className="title">{t(VehicleStatusMessages.currentSpeed)}</div>
                <div
                  className="text"
                  style={{ color: !vehicle.current_speed
                    ? StatusColor.INACTIVE
                    : (vehicle.maximum_speed_allowed && (Number(vehicle.current_speed) * InternalParams.SPEED_MARGIN) > Number(vehicle.maximum_speed_allowed))
                      ? StatusColor.ERROR
                      : StatusColor.ACTIVE }}
                >
                  {vehicle.current_speed ? (Math.floor(vehicle.current_speed * InternalParams.SPEED_MARGIN)) : <RemoveIcon />}/
                  {vehicle.maximum_speed_allowed ? Math.floor(vehicle.maximum_speed_allowed) : <RemoveIcon />}
                  &nbsp;Km/h
                </div>
              </div>
            </div>
            <div className="content ignition">
              <div className="icon">
                <ReactSVG
                  className="smallIcon"
                  src={IconIgnitionStatus}
                  style={{ fill: vehicle.engine ? StatusColor.ACTIVE : StatusColor.INACTIVE }}
                />
              </div>
              <div className="boxContent">
                <div className="title" style={{ paddingLeft: "13px" }}>{t(VehicleStatusMessages.engine)}</div>
                <div
                  className="text"
                  style={{ color: vehicle.engine ? StatusColor.ACTIVE : StatusColor.INACTIVE }}
                >
                  {vehicle.engine ? t(VehicleStatusMessages.engineOn).toUpperCase() : t(VehicleStatusMessages.engineOff).toUpperCase() }
                </div>
              </div>
            </div>
            <div className="content hour-meter travel-hour-meter">
              <div className="icon">
                <AccessTimeIcon
                  style={{ fill: StatusColor.ACTIVE }}
                />
              </div>
              <div className="boxContent">
                <div className="title">{t(VehicleStatusMessages.engineRunningTime)}</div>
                <div
                  className="text"
                  style={{ color: StatusColor.ACTIVE }}
                >
                  {vehicle.current_hour_meter && vehicle?.current_travel?.start_hour_meter
                    ? formatHourMeter(vehicle.current_hour_meter - vehicle?.current_travel?.start_hour_meter)
                    : <RemoveIcon />}
                </div>
              </div>
            </div>
            <div className="content odometer travel-odometer">
              <div className="icon">
                <ReactSVG
                  src={IconOdometer}
                  style={{ fill: StatusColor.ACTIVE }}
                />
              </div>
              <div className="boxContent">
                <div className="title">{t(VehicleStatusMessages.travel)}</div>
                <div
                  className="text"
                  style={{ color: StatusColor.ACTIVE }}
                >
                  {vehicle.current_odometer && vehicle?.current_travel?.start_odometer
                    ? formatOdometer(vehicle.current_odometer - vehicle?.current_travel?.start_odometer)
                    : <RemoveIcon />}
                </div>
              </div>
            </div>
            <div className="content odometer total-odometer">
              <div className="icon">
                <ReactSVG
                  src={IconOdometer}
                  style={{ fill: StatusColor.ACTIVE }}
                />
              </div>
              <div className="boxContent">
                <div className="title">{t(VehicleStatusMessages.odometer)}</div>
                <div
                  className="text"
                  style={{ color: StatusColor.ACTIVE }}
                >
                  {vehicle.current_odometer ? formatOdometer(vehicle.current_odometer) : <RemoveIcon />}
                </div>
              </div>
            </div>
          </div>
          <div className="block lastCommunicate">
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div className="icon">
                  <ReactSVG src={IconConnection} />
                </div>
                <Typography component="div">{t(VehicleStatusMessages.latestDataSent)}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography component="div">
                  <div className="content">
                    <div className="boxContent">
                      <div className="title">{t(VehicleStatusMessages.tracker)}</div>
                      <div className="text">
                        {vehicle.hardwares && utils.formatDateIfHave(vehicle.hardwares[0].last_send_date, "fullDate")}<br />
                        {utils.formatDateIfHave(utils.calcDataRange(vehicle.hardwares[0].last_send_date, new Date()), "durationDescriptiveTime", {
                          durationDescriptiveTime: {
                            onlyMinutes: true
                          }
                        })}
                      </div>
                    </div>
                  </div>
                  {vehicle.hardwares && vehicle.hardwares[1] && (
                  <>
                    {vehicle.hardwares[1].last_load_date && (
                    <div className="content">
                      <div className="boxContent">
                        <div className="title">
                          {t(VehicleStatusMessages.lastLoadDateDescription)}
                        </div>
                        <div className="text">
                          {utils.formatDateIfHave(
                            vehicle.hardwares[1].last_load_date,
                            "fullDate"
                          )}<br />
                          {utils.formatDateIfHave(utils.calcDataRange(vehicle.hardwares[1].last_load_date, new Date()), "durationDescriptiveTime", {
                            durationDescriptiveTime: {
                              onlyMinutes: true
                            }
                          })}
                        </div>
                      </div>
                    </div>
                    )}
                    {vehicle.hardwares[1].last_unload_date && (
                    <div className="content">
                      <div className="boxContent">
                        <div className="title">
                          {t(VehicleStatusMessages.lastUnloadDateDescription)}
                        </div>
                        <div className="text">
                          {utils.formatDateIfHave(
                            vehicle.hardwares[1].last_unload_date,
                            "fullDate"
                          )}<br />
                          {utils.formatDateIfHave(utils.calcDataRange(vehicle.hardwares[1].last_unload_date, new Date()), "durationDescriptiveTime", {
                            durationDescriptiveTime: {
                              onlyMinutes: true
                            }
                          })}
                        </div>
                      </div>
                    </div>
                    )}
                  </>
                  )}
                  {vehicle.type.description === VehicleTypes.CAMINHAO_BOMBA && vehicle.hardwares && (
                  <>
                    {vehicle.hardwares[0].last_unload_date && (
                    <div className="content">
                      <div className="boxContent">
                        <div className="title">
                          {t(VehicleStatusMessages.pumpingTimeDescription)}
                        </div>
                        <div className="text">
                          {utils.formatDateIfHave(
                            vehicle.hardwares[0].last_unload_date,
                            "fullDate"
                          )}<br />
                          {utils.formatDateIfHave(utils.calcDataRange(vehicle.hardwares[0].last_unload_date, new Date()), "durationDescriptiveTime", {
                            durationDescriptiveTime: {
                              onlyMinutes: true
                            }
                          })}
                        </div>
                      </div>
                    </div>
                    )}
                  </>
                  )}
                </Typography>
              </AccordionDetails>
            </Accordion>
            {utils.hasCanBus(vehicle) && (
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <div className="icon">
                    <ReactSVG src={IconCanTelemetry} />
                  </div>
                  <Typography component="div">{t(VehicleStatusMessages.CANTelemetry)}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography component="div">
                    {vehicle?.current_info?.can_engine !== null && (
                      <div className="content">
                        <div className="boxContent">
                          <div className="title">{t(VehicleStatusMessages.engine)}</div>
                          <div className="text">
                            {vehicle?.current_info?.can_engine
                              ? t(VehicleStatusMessages.engineOn).toUpperCase()
                              : t(VehicleStatusMessages.engineOff).toUpperCase()}
                          </div>
                        </div>
                      </div>
                    )}
                    {utils.isValidNumber(vehicle?.current_info?.can_odometer) && (
                      <div className="content">
                        <div className="boxContent">
                          <div className="title">{t(VehicleStatusMessages.odometer)}</div>
                          <div className="text">
                            {formatOdometer(vehicle!.current_info!.can_odometer!)}
                          </div>
                        </div>
                      </div>
                    )}
                    {utils.isValidNumber(vehicle?.current_info?.can_total_fuel_used) && (
                      <div className="content">
                        <div className="boxContent">
                          <div className="title">{t(VehicleStatusMessages.totalFuelConsumption)}</div>
                          <div className="text">
                            {utils.formatNumberToLocaleString(vehicle?.current_info?.can_total_fuel_used)} L
                          </div>
                        </div>
                      </div>
                    )}
                    {utils.isValidNumber(vehicle?.current_info?.can_speed) && (
                      <div className="content">
                        <div className="boxContent">
                          <div className="title">{t(GlobalMessages.speed)}</div>
                          <div className="text">
                            {utils.formatNumberToLocaleString(vehicle?.current_info?.can_speed)} Km/h
                          </div>
                        </div>
                      </div>
                    )}
                    {utils.isValidNumber(vehicle?.current_info?.can_engine_rpm) && (
                      <div className="content">
                        <div className="boxContent">
                          <div className="title">RPM</div>
                          <div className="text">
                            {utils.formatNumberToLocaleString(vehicle?.current_info?.can_engine_rpm)}
                          </div>
                        </div>
                      </div>
                    )}
                    {utils.isValidNumber(vehicle?.current_info?.can_engine_coolant_temperature) && (
                      <div className="content">
                        <div className="boxContent">
                          <div className="title">{t(VehicleStatusMessages.radiatorTemperature)}</div>
                          <div className="text">
                            {utils.formatNumberToLocaleString(vehicle?.current_info?.can_engine_coolant_temperature)} °C
                          </div>
                        </div>
                      </div>
                    )}
                    {vehicle?.current_info?.can_fuel_consumption && (
                      <div className="content">
                        <div className="boxContent">
                          <div className="title">{t(VehicleStatusMessages.averageFuelConsumption)}</div>
                          <div className="text">
                            {utils.formatFuelConsumption(vehicle?.current_info?.can_fuel_consumption)}
                          </div>
                        </div>
                      </div>
                    )}
                    {vehicle?.current_info?.can_fuel_level && (
                      <div className="content">
                        <div className="boxContent">
                          <div className="title">{t(VehicleStatusMessages.fuelLevel)}</div>
                          <div className="text">
                            {utils.formatFuelLevel(vehicle?.current_info?.can_fuel_level)}
                          </div>
                        </div>
                      </div>
                    )}
                    {utils.isValidNumber(vehicle?.current_info?.can_total_engine_hours) && (
                      <div className="content">
                        <div className="boxContent">
                          <div className="title">{t(VehicleStatusMessages.totalEngineRunningTime)}</div>
                          <div className="text">
                            {utils.formatNumberToLocaleString(vehicle?.current_info?.can_total_engine_hours)}
                          </div>
                        </div>
                      </div>
                    )}
                    {utils.isValidNumber(vehicle?.current_info?.can_total_driving_time) && (
                      <div className="content">
                        <div className="boxContent">
                          <div className="title">{t(VehicleStatusMessages.totalDrivingTime)}</div>
                          <div className="text">
                            {utils.formatNumberToLocaleString(vehicle?.current_info?.can_total_driving_time)}
                          </div>
                        </div>
                      </div>
                    )}
                    {utils.isValidNumber(vehicle?.current_info?.can_total_idle_fuel_used) && (
                      <div className="content">
                        <div className="boxContent">
                          <div className="title">{t(VehicleStatusMessages.totalFuelUsedInIdle)}</div>
                          <div className="text">
                            {utils.formatNumberToLocaleString(vehicle?.current_info?.can_total_idle_fuel_used)} L
                          </div>
                        </div>
                      </div>
                    )}
                    {utils.isValidNumber(vehicle?.current_info?.can_gross_combination_vehicle_weight) && (
                      <div className="content">
                        <div className="boxContent">
                          <div className="title">{t(VehicleStatusMessages.combinedGrossVehicleWeight)}</div>
                          <div className="text">
                            {utils.formatNumberToLocaleString(vehicle?.current_info?.can_gross_combination_vehicle_weight)}
                          </div>
                        </div>
                      </div>
                    )}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            )}
          </div>
        </>
      )}
    </Container>
  );
});

export default VehicleStatus;
