import { TranslationVehiclesModuleMessages, VehiclesModuleMessages } from "../interfaces";

export const messages: TranslationVehiclesModuleMessages = {

  /* region Backend messages */

  /* region Vehicle-events messages */
  [VehiclesModuleMessages.updateVehicleEventSuccess]: "Evento alterado com sucesso",
  [VehiclesModuleMessages.eventNotFound]: "Evento não cadastrado em nosso sistema",
  [VehiclesModuleMessages.updateVehicleEventError]: "Falha ao atualizar evento",
  [VehiclesModuleMessages.readVehicleEventSuccess]: "Evento(s) retornado(s) com sucesso",
  [VehiclesModuleMessages.noVehicleEventFound]: "Nenhum evento cadastrado para a viagem em questão",
  [VehiclesModuleMessages.readVehicleEventError]: "Falha ao buscar evento(s)",
  /* endregion */
  /* region Vehicles-states messages */
  [VehiclesModuleMessages.createVehicleStateSuccess]: "Estado de veículo cadastrado com sucesso",
  [VehiclesModuleMessages.createVehicleStateError]: "Falha ao cadastrar estado de veículo",
  [VehiclesModuleMessages.readVehicleStateSuccess]: "Estado(s) de veículo retornado(s) com sucesso",
  [VehiclesModuleMessages.noVehicleStateFound]: "Nenhum estado de veículo cadastrado",
  [VehiclesModuleMessages.readVehicleStateError]: "Falha ao buscar estado(s) de veículo(s)",
  [VehiclesModuleMessages.noVehicleStateInTravelFound]: "Nenhum estado de veículo cadastrado para a viagem em questão",
  [VehiclesModuleMessages.getVehiclesAndTravelsError]: "Falha ao buscar informações dos veículos e viagens",
  [VehiclesModuleMessages.getVehiclesHardwaresError]: "Falha ao buscar informações dos hardwares dos veículos",
  [VehiclesModuleMessages.getVehiclesAlertsError]: "Falha ao buscar informações dos alertas dos veículos",
  [VehiclesModuleMessages.getMixerTravelsSpecificInfoError]: "Falha ao buscar informações específicas das viagens de betoneira",
  [VehiclesModuleMessages.getPumpTravelsSpecificInfoError]: "Falha ao buscar informações específicas das viagens de bomba",
  [VehiclesModuleMessages.getAggregateTruckTravelsSpecificInfoError]: "Falha ao buscar informações específicas das viagens de caminhão de agregado",
  [VehiclesModuleMessages.noVehicleStateInVehicleFound]: "Nenhum estado de veículo cadastrado para o veículo ",
  /* endregion */
  /* region Vehicle-states-types messages */
  [VehiclesModuleMessages.readVehicleStateTypeSuccess]: "Tipo(s) de estados de veículo retornado(s) com sucesso",
  [VehiclesModuleMessages.noVehicleStateTypeFound]: "Nenhum tipo de estado de veículo cadastrado",
  [VehiclesModuleMessages.readVehicleStateTypeError]: "Falha ao buscar tipo(s) de estado(s) de veículo(s)",
  /* endregion */
  /* region Vehicle-types messages */
  [VehiclesModuleMessages.readVehicleTypeSuccess]: "Tipo(s) de veículo(s) retornado(s) com sucesso",
  [VehiclesModuleMessages.noVehicleTypeFound]: "Nenhum tipo de veículo cadastrado",
  [VehiclesModuleMessages.readVehicleTypeError]: "Falha ao buscar tipo(s) de veículo(s)",
  /* endregion */
  /* region Vehicles messages */
  [VehiclesModuleMessages.createVehicleSuccess]: "Veículo cadastrado com sucesso",
  [VehiclesModuleMessages.createVehicleError]: "Falha ao cadastrar veículo",
  [VehiclesModuleMessages.readVehicleSuccess]: "Veículo(s) retornado(s) com sucesso",
  [VehiclesModuleMessages.noVehicleFound]: "Nenhum veículo encontrado",
  [VehiclesModuleMessages.readVehicleError]: "Falha ao buscar veículo(s)",
  [VehiclesModuleMessages.updateVehicleSuccess]: "Veículo alterado com sucesso",
  [VehiclesModuleMessages.vehicleNotFound]: "Veículo não cadastrado em nosso sistema",
  [VehiclesModuleMessages.updateVehicleError]: "Falha ao atualizar veículo",
  [VehiclesModuleMessages.deleteVehicleSuccess]: "Veículo excluído com sucesso",
  [VehiclesModuleMessages.onlyInactivateVehicleMessage]: "Veículo com mais de um estado não pode ser excluído, apenas inativado",
  [VehiclesModuleMessages.deleteVehicleError]: "Falha ao excluir veículo",
  [VehiclesModuleMessages.code]: "Código",
  [VehiclesModuleMessages.licensePlate]: "Placa",
  [VehiclesModuleMessages.invalidUserOrPassword]: "Usuário ou senha inválidos",
  [VehiclesModuleMessages.userNotAuthorizedToBlockVehicle]: "Usuário não autorizado a bloquear veículo",
  [VehiclesModuleMessages.vehicleWithoutHardware]: "Veículo não possui hardware",
  [VehiclesModuleMessages.vehicleWithoutBlockCapableHardware]: "Veículo não possui hardware com bloqueio",
  [VehiclesModuleMessages.blockRequestSent]: "Bloqueio solicitado com sucesso",
  [VehiclesModuleMessages.unblockRequestSent]: "Desbloqueio solicitado com sucesso",
  [VehiclesModuleMessages.blockRequestError]: "Falha ao solicitar bloqueio/desbloqueio",
  [VehiclesModuleMessages.general]: "Geral",
  [VehiclesModuleMessages.total]: "Total",
  [VehiclesModuleMessages.average]: "Média",

  /* region Vehicle types */
  [VehiclesModuleMessages.concreteMixers]: "Betoneiras",
  [VehiclesModuleMessages.concreteMixer]: "Betoneira",
  [VehiclesModuleMessages.pumpTrucks]: "Caminhões bomba",
  [VehiclesModuleMessages.pumpTruck]: "Caminhão bomba",
  [VehiclesModuleMessages.supportVehicles]: "Veículos de apoio",
  [VehiclesModuleMessages.supportVehicle]: "Veículo de apoio",
  [VehiclesModuleMessages.loaders]: "Pás carregadeiras",
  [VehiclesModuleMessages.loader]: "Pá carregadeira",
  [VehiclesModuleMessages.aggregateTrucks]: "Carretas de agregados",
  [VehiclesModuleMessages.aggregateTruck]: "Carreta de agregados",
  [VehiclesModuleMessages.siloTrucks]: "Carretas silo",
  [VehiclesModuleMessages.siloTruck]: "Carreta silo",
  /* endregion */

  /* endregion */

  /* region Integration messages */
  [VehiclesModuleMessages.locationNotRegistered]: "Local(is) não cadastrado(s)",
  [VehiclesModuleMessages.driverNotRegistered]: "Motorista(s) não cadastrado(s)",
  [VehiclesModuleMessages.externalIDAlreadyRegistered]: "ID(s) externos já cadastrado(s)",
  [VehiclesModuleMessages.licensePlateAlreadyRegistered]: "Placa(s) já cadastrada(s)",
  [VehiclesModuleMessages.codeAlreadyRegistered]: "Código(s) já cadastrado(s)",
  [VehiclesModuleMessages.invalidDateFormat]: "Formato de data inválido",
  [VehiclesModuleMessages.noDataForThisVehicleOrDay]: "Não há dados para esse veículo e/ou dia",
  [VehiclesModuleMessages.readVehicleDailySummarySuccess]: "Resumo diário do(s) veículo(s) obtido(s) com sucesso",
  [VehiclesModuleMessages.vehicleWithoutTracker]: "Veículo sem rastreador vinculado",
  /* endregion */

  /* endregion */
  /* region Frontend messages */
  [VehiclesModuleMessages.description]: "Descrição",
  [VehiclesModuleMessages.status]: "Status",
  [VehiclesModuleMessages.odometer]: "Odômetro",
  [VehiclesModuleMessages.odometerInKm]: "Odômetro (km)",
  [VehiclesModuleMessages.hourMeter]: "Horímetro",
  [VehiclesModuleMessages.hourMeterInHours]: "Horímetro (h)",
  [VehiclesModuleMessages.maintenance]: "Em manutenção",
  [VehiclesModuleMessages.originalAllocation]: "Alocação original",
  [VehiclesModuleMessages.currentAllocation]: "Alocação atual",
  [VehiclesModuleMessages.currentEvent]: "Evento atual",
  [VehiclesModuleMessages.searchingVehicles]: "Buscando veículos...",
  [VehiclesModuleMessages.vehicleDeleteConfirmation]: "Tem certeza que deseja excluir o veículo",
  [VehiclesModuleMessages.vehicleRegistration]: "Cadastro de veículo",
  [VehiclesModuleMessages.activeVehicle]: "Veículo ativo",
  [VehiclesModuleMessages.vehicleUnderMaintenance]: "Veículo em manutenção",
  [VehiclesModuleMessages.vehicleType]: "Tipo de veículo",
  [VehiclesModuleMessages.maxSpeedAllowed]: "Velocidade máxima permitida",
  [VehiclesModuleMessages.allocationPlant]: "Usina de alocação",
  [VehiclesModuleMessages.defaultDriver]: "Motorista padrão",
  [VehiclesModuleMessages.title]: "Veículos",
  [VehiclesModuleMessages.deleteDialogTitle]: "Confirmação de exclusão",
  [VehiclesModuleMessages.vehicles]: "Veículos",
  [VehiclesModuleMessages.blocks]: "Bloqueios"
  /* endregion */
};
